import React from "react";
import { Accounts } from "../components/Accounts";
import AccessRole from "../components/Accounts/AccessRole";
import AccountGroup from "../components/Accounts/AccountGroup";
import { Logs } from "../components/Logs";
import { General } from "../components/Profile";
import { Tabs } from "../components/Tabs";
import { Webhook } from "../components/Webhook";
import { SmsLogs } from "../components/SmsLogs";
import { getStorage } from "../utils/storage";

class AccountScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  renderGeneral() {
    return (
      <div className="card-body">
        <General />
      </div>
    );
  }

  renderLogs() {
    return (
      <div className="card-body">
        <Logs />
      </div>
    );
  }

  renderWebhook() {
    return (
      <div className="card-body">
        <Webhook />
      </div>
    );
  }

  renderPermission() {
    return (
      <div className="card-body">
        <AccessRole {...this.state.user} />
      </div>
    );
  }

  renderAccount() {
    return (
      <div className="card-body">
        <Accounts />
      </div>
    );
  }

  renderGroup() {
    return (
      <div className="card-body">
        <AccountGroup />
      </div>
    );
  }
  render() {
    let options = [
      {
        title: "General",
        data: this.renderGeneral(),
      },
    ];

    if (
      this.state.user.account_type === "user_account" ||
      this.state.user.account_type === "user_subaccount"
    ) {
      options.push({
        title: "Permission",
        data: this.renderPermission(),
      });
    }

    options.push({
      title: "Accounts",
      data: this.renderAccount(),
    });

    if (
      this.state.user.account_type === "admin_account" ||
      this.state.user.account_type === "user_account"
    ) {
      options.push({
        title: "Groups",
        data: this.renderGroup(),
      });
    }

    if (this.state.user.account_type === "admin_account") {
      options.push(
        {
          title: 'User Logs',
          data: this.renderLogs(),
        },
        {
          title: 'Webhook',
          data: this.renderWebhook(),
        },
      );
    }

    return (
      <div className="card">
        <Tabs options={options} />
      </div>
    );
  }
}

export default AccountScreen;
