import React from 'react';
import {Link} from 'react-router-dom';
import Table from '../Table/Table';
import './styles.css';
import {getStorage} from '../../utils/storage';
import {ENDPOINT} from '../../constants/api';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';
import {connect} from 'react-redux';
import {Modal} from '../Modal';
import {AlertInfo} from '../Alerts';
import {ThingInfo} from '../Thing';

class RecentCollections extends React.Component {
  state = {
    data: [],
    user: {},
    selected_alert: {},
    selected_thing: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const {user} = this.state;
    const {filters} = this.props;

    this.setState({
      isLoading,
    });

    let request_body = {
      status: 'served',
      page: 1,
      limit: 5,
    };

    if (filters && filters.locations && filters.locations.length > 0) {
      request_body.sectors = filters.locations;
    }

    if (
      filters &&
      (filters.level_min || filters.level_min === 0) &&
      filters.level_max
    ) {
      request_body.start_level_percentage =
        filters.level_min !== '' ? filters.level_min : undefined;

      request_body.end_level_percentage =
        filters.level_max !== '' ? filters.level_max : undefined;
    }

    if (filters && filters.just_collected) {
      request_body.just_collected = true;
    }

    if (filters && filters.types) {
      request_body.types = filters.types.length > 0 ? filters.types : undefined;
    }

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_schedule_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data, count} = res.data;

        let results = [];

        for (let i = 0; i < data.length; i++) {
          results.push({
            ...data[i],
            alert: 'Alert',
          });
        }

        this.setState({
          data: results,
          isLoading: false,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  handOpenModal(modal, selected_item) {
    let modalTitle = '',
      selected_alert = {},
      selected_thing = {};
    if (modal === 'showModal') {
      modalTitle = selected_item.thing_id.thing_id + ' ' + selected_item.alert;
      selected_alert = selected_item;
    }

    if (modal === 'showThingModal') {
      modalTitle = selected_item.thing_id.thing_id;
      selected_thing = selected_item.thing_id;
    }
    this.setState({
      [modal]: true,
      modalTitle,
      selected_alert,
      selected_thing,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <h3>Top 5 Recent Collections</h3>
        </div>
        <div className="card-body" style={{paddingBottom: 0}}>
          <Table
            data={this.state.data}
            isLoading={this.state.isLoading}
            no_bordered
            style={{marginBottom: 0}}
            headers={[
              {
                title: 'Object ID',
                key: 'thing_id.thing_id',
                type: 'link',
                onPress: this.handOpenModal.bind(this, 'showThingModal'),
              },
              {
                title: 'Last Collected',
                key: 'served_date',
                isMoment: true,
                formatTime: 'lll',
              },
              {
                title: 'Levels',
                key: 'level',
                type: 'level',
              },
              {
                title: 'Alert',
                key: 'alert',
                type: 'link',
                onPress: this.handOpenModal.bind(this, 'showModal'),
              },
            ]}
          />
        </div>
        {this.state.data.length > 0 && (
          <center className="card-footer border-top">
            <Link
              to="/dashboard/schedules/served_schedules"
              className="text-primary">
              <b>View More</b>
            </Link>
          </center>
        )}
        <Modal
          handleClose={this.handleCloseModal.bind(this, 'showModal')}
          show={this.state.showModal}
          title={this.state.modalTitle}
          size="lg">
          <AlertInfo
            {...this.state.selected_alert}
            handleCloseModal={this.handleCloseModal.bind(this, 'showModal')}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, 'showThingModal')}
          show={this.state.showThingModal}
          title={this.state.modalTitle}
          size="lg">
          <ThingInfo
            {...this.state.selected_thing}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              'showThingModal',
            )}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {filters} = state.Filters;
  return {filters};
};

export default connect(mapStateToProps)(RecentCollections);
