import React from 'react';
import './styles.css';
import LOGO from '../../../assets/logo.png';
import Button from '../../Button/Button';

class NavBar extends React.Component {
  state = {
    active_menu: 'home',
    scrollY: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll.bind(this), {
      passive: true,
    });
  }

  onScroll() {
    const scrollY = window.pageYOffset;
    let active_menu = this.state.active_menu;

    if (scrollY >= 0 && scrollY < 200) {
      active_menu = 'home';
    } else if (scrollY >= 200 && scrollY < 800) {
      active_menu = 'partners';
    }
    if (scrollY >= 800 && scrollY < 3685.5) {
      active_menu = 'features';
    }

    if (scrollY >= 3685.5 && scrollY < 4764) {
      active_menu = 'contact us';
    }

    this.setState({active_menu});

    const navbar = document.getElementById('navbar');

    let classList;

    if (!navbar) return;
    classList = navbar.classList;

    if (scrollY > 800 && !classList.contains('fixed-top')) {
      classList.add('fixed-top');
    } else if (classList.contains('fixed-top') && scrollY == 0) {
      classList.remove('fixed-top');
    }
  }
  render() {
    const menus = [
      {
        name: 'Home',
        route: '/#home',
      },
      {
        name: 'Partners',
        route: '/#partners',
      },
      {
        name: 'Features',
        route: '/#features',
      },

      {
        name: 'Contact Us',
        route: '/#contactus',
      },
    ];

    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light"
        id="navbar">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={LOGO} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            {/* <span className="navbar-toggler-icon"></span> */}
            <i className="bx bx-menu customMenuIcon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {menus.map((menu, m) => {
                return (
                  <li key={m} className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.active_menu === menu.name.toLowerCase() &&
                        'active'
                      }`}
                      href={menu.route}>
                      {menu.name}
                    </a>
                  </li>
                );
              })}
            </ul>
            <Button
              onPress={() => {
                window.location.href = '/login';
              }}
              text="Login"
              className="btn-primary"
            />
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
