export default data => {
  return (
    <ul className="chart-list">
      {data &&
        data.map((item, i) => {
          let title = item.split(':');
          return (
            <li key={i}>
              <div style={{flex: 1, marginRight: 20}}>
                <p title={title[0]} className="title">
                  {title[0]}
                </p>
              </div>

              <div>
                <span className="number">{title[1]}</span>
              </div>
            </li>
          );
        })}
    </ul>
  );
};
