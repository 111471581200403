import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import './responsive.css';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';

//screens
import LoginScreen from './app/screen/LoginScreen';
import ResetPasswordScreen from './app/screen/ResetPasswordScreen';
import HomeDashBoardScreen from './app/screen/HomeDashBoardScreen';
import WaterMapScreen from './app/screen/WaterMapScreen';
import ForgotPasswordScreen from './app/screen/ForgotPasswordScreen';
import WaterActivitiesScreen from './app/screen/WaterActivitiesScreen';
import AccountScreen from './app/screen/AccountScreen';
import SensorActivitiesScreen from './app/screen/SensorActivitiesScreen';
import WaterLevelScreen from './app/screen/WaterLevelScreen';
import PendingScheduleScreen from './app/screen/PendingScheduleScreen';
import AlertWaterThingLevelScreen from './app/screen/AlertWaterThingLevelScreen';
import AlertSensorStateScreen from './app/screen/AlertSensorStateScreen';
import IncidentWaterThingLevelScreen from './app/screen/IncidentWaterThingLevelScreen';
import IncidentSensorStateScreen from './app/screen/IncidentSensorStateScreen';
import DeviceThingScreen from './app/screen/DeviceThingScreen';
import NotificationScreen from './app/screen/NotificationScreen';
import WaterTankScreen from './app/screen/WaterTankScreen';
import CheckedVehicleScreen from './app/screen/CheckedVehicleScreen';
import VehicleRecognitionAnalytics from './app/screen/VehicleRecognitionAnalytics';
import LandingPageScreen from './app/screen/LandingPageScreen';
import ElectricityFlowScreen from './app/screen/ElectricityFlowScreen';
import ElectricityAnalyticsScreen from './app/screen/ElectricityAnalyticsScreen';
import AirQualityScreen from './app/screen/AirQualityScreen';
import AirQualityAnalyticsScreen from './app/screen/AirQualityAnalyticsScreen';

const createBrowserHistory = require('history').createBrowserHistory;

function App() {
  return (
    <div className="App">
      <Router history={createBrowserHistory({queryKey: false})}>
        <Switch>
          <Route exact path="/" component={LandingPageScreen} />
          <Route exact path="/login" component={LoginScreen} />
          <Route
            path="/dashboard"
            render={props => {
              const {url} = props.match;
              return (
                <HomeDashBoardScreen {...props}>
                  <Route
                    exact
                    path={`${url}/home`}
                    component={WaterMapScreen}
                  />
                  <Route
                    exact
                    path={`${url}/home/Water_activities`}
                    component={WaterActivitiesScreen}
                  />
                  <Route
                    exact
                    path={`${url}/analytics/sensor_activities`}
                    component={SensorActivitiesScreen}
                  />
                  <Route
                    exact
                    path={`${url}/analytics/water_levels`}
                    component={WaterLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/alerts_&_incidents/alerts_levels`}
                    component={AlertWaterThingLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/alerts_&_incidents/Water_thing levels/:thing_id`}
                    component={AlertWaterThingLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/alerts_&_incidents/alerts_sensors`}
                    component={AlertSensorStateScreen}
                  />
                  <Route
                    exact
                    path={`${url}/incidents/Water_thing levels`}
                    component={IncidentWaterThingLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/incidents/sensor_state`}
                    component={IncidentSensorStateScreen}
                  />
                  <Route
                    exact
                    path={`${url}/schedules/pending_schedules`}
                    component={PendingScheduleScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/object_devices`}
                    component={DeviceThingScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/accounts`}
                    component={AccountScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/notifications`}
                    component={NotificationScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/notifications/id`}
                    component={NotificationScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/series_reports/water_levels`}
                    component={WaterTankScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/series_reports/vehicle_recognition`}
                    component={CheckedVehicleScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/analytics/traffic_insights`}
                    component={VehicleRecognitionAnalytics}
                  />
                  <Route
                    exact
                    path={`/dashboard/series_reports/electricity_flow`}
                    component={ElectricityFlowScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/analytics/electricity_flow`}
                    component={ElectricityAnalyticsScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/series_reports/air_quality`}
                    component={AirQualityScreen}
                  />

                  <Route
                    exact
                    path={`/dashboard/analytics/air_quality`}
                    component={AirQualityAnalyticsScreen}
                  />
                </HomeDashBoardScreen>
              );
            }}
          />
          <Route
            exact
            path="/forgot_password"
            component={ForgotPasswordScreen}
          />

          <Route
            exact
            path="/reset_password/:username"
            component={ResetPasswordScreen}
          />
        </Switch>
      </Router>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
    </div>
  );
}

export default App;
