import React from 'react';
import {Button} from '../Button';
import {Input, Select, Switch} from '../Input';
import validateEmail from '../../utils/validateEmail';

// import "./style.css";
import {ENDPOINT} from '../../constants/api';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';
import {account_roles} from '../../constants/strings';
import {Tabs} from '../Tabs';
import {getStorage} from '../../utils/storage';
import AccessRole from './AccessRole';
import Group from './Group';
import {connect} from 'react-redux';
import {onToggleAllNotification} from '../../action/Notification';

class NewAccount extends React.Component {
  state = {
    email: '',
    password: '',
    error: {},
    isSubmitting: false,
    user: {},
    _id: '',
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    if (this.props._id && this.props._id !== '') {
      let role = this.populateSelect(
        account_roles[this.state.user.account_type],
        this.props.account_role,
      );

      this.setState({
        ...this.props,
        receive_all_notification: this.props.notification,
        role,
      });
    }
  };

  populateSelect(data, account_role = 'admin') {
    let current_item = {};
    for (let i = 0; i < data.length; i++) {
      if (data[i].value === account_role) {
        current_item = data[i];
      }
    }

    return current_item;
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  onChangeText(name, e) {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    this.setState({
      error,
      [name]: value,
    });
  }

  validateForm() {
    let {email, error, password, role, _id} = this.state;

    if (email === '') {
      error.email = 'Email address is required';
    } else if (!validateEmail(email)) {
      error.email = 'Invalid email address';
    }

    if (_id === '' && password === '') {
      error.password = 'Password is required';
    }

    if (!role) {
      error.role = 'Role is required';
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      email,
      password,
      error,
      role,
      contact,
      user,
      _id,
      receive_all_notification,
    } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let _role = '';
      if (role.value) {
        _role = role.value;
      }

      let url = '';

      if (user.account_type === 'admin_account') {
        url = ENDPOINT + '/register_user_account';
      }

      if (user.account_type === 'user_account') {
        url = ENDPOINT + '/register_user_subaccount';
      }

      if (user.account_type === 'admin_account' && _id !== '') {
        url = ENDPOINT + '/update_user_account';
      }

      if (user.account_type === 'user_account' && _id !== '') {
        url = ENDPOINT + '/update_user_subaccount';
      }

      let data = {
        email,
        password,
        contact,
        account_role: _role,
        ref_account: user.id,
      };

      if (receive_all_notification) {
        data.notification = receive_all_notification;
      }

      if (_id !== '') {
        delete data.password;

        data.id = _id;
      }

      const options = {
        method: 'POST',
        url,
        data,
        headers: {
          authorization: 'Bearer ' + user.token,
        },
      };

      axios(options)
        .then(data => {
          this.setState({
            isSubmitting: false,
            email: '',
            password: '',
            role: {},
            contact: '',
          });

          toastMessage(
            'success',
            `Account ${
              this.state._id !== '' ? 'updated' : 'created'
            } successfully`,
          );

          this.props.handleCloseModal();
          this.props.getAccounts(true);
        })
        .catch(error => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage('error', error);
        });
    }
  };

  handleNotificationToggle = e => {
    const {user} = this.state;

    if (user.id === this.props._id) {
      this.props.dispatch(onToggleAllNotification(e.target.checked));
    } else {
      this.setState({
        receive_all_notification: e.target.checked,
      });
    }
  };

  renderForm() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <Input
                label="Email Address:"
                required
                className="form-control-lg"
                value={this.state.email}
                onChange={e => this.onChangeText('email', e)}
                error={this.state.error.email}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Contact:"
                className="form-control-lg "
                value={this.state.contact}
                onChange={e => this.onChangeText('contact', e)}
                error={this.state.error.contact}
              />
            </div>
            {(!this.state._id || this.state._id === '') && (
              <div className="col-md-6">
                <Input
                  label="Password:"
                  className="form-control-lg "
                  value={this.state.password}
                  onChange={e => this.onChangeText('password', e)}
                  error={this.state.error.password}
                  type="password"
                  required
                />
              </div>
            )}
            <div className="col-md-6">
              <Select
                options={account_roles[this.state.user.account_type]}
                label="Account Role:"
                className="form-control-lg "
                value={this.state.role}
                onChange={e => this.onChangeText('role', e)}
                error={this.state.error.role}
                required
              />
            </div>
          </div>
          {(this.state.user.account_type === 'admin_account' ||
            this.state.user.account_type === 'user_account') && (
            <>
              <hr />
              <div className="notification-control-box">
                <div className="icon-notification">
                  <i className="bx bx-bell" />
                </div>
                <div className="notification-info">
                  <div>
                    <h1>Notification</h1>
                    <p>Get notification when Water levels are high</p>
                  </div>
                </div>
                <Switch
                  checked={this.state.receive_all_notification}
                  onChange={this.handleNotificationToggle.bind(this)}
                />
              </div>
            </>
          )}
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderAccessLevel() {
    if (this.props.access_role) {
      return (
        <AccessRole {...this.props} getAccounts={this.props.getAccounts} />
      );
    }

    return <div></div>;
  }

  // renderGroup() {
  //   return <Group {...this.props} getAccounts={this.props.getAccounts} />;
  // }

  render() {
    return (
      <Tabs
        options={[
          {
            title: 'Account Details',
            data: this.renderForm(),
          },
          {
            title: 'Access Level',
            data: this.renderAccessLevel(),
          },
          // {
          //   title: "Group",
          //   data: this.renderGroup(),
          // },
        ]}
      />
    );
  }
}

const mapStateToProps = state => {
  const {receive_all_notification} = state.Notification;
  return {
    receive_all_notification,
  };
};

export default connect(mapStateToProps)(NewAccount);
