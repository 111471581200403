import React from 'react';
import './styles.css';
import {Pie} from 'react-chartjs-2';
import {getStorage} from '../../utils/storage';
import {ENDPOINT} from '../../constants/api';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';
import {connect} from 'react-redux';
import {LoadingSpinner} from '../LoadingSpinner';
import {colors} from '../../constants/strings';

const options = {
  legend: {
    position: 'left',
    display: true,
  },
  maintainAspectRatio: false,
  offset: true,
};

class WaterLevelPerType extends React.Component {
  state = {
    data: [],
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const {user} = this.state;
    const {filters} = this.props;

    this.setState({
      isLoading,
    });

    let request_body = {
      tag: 'trend',
      target: 'all',
      order: 'thing_type',
    };

    if (filters && filters.locations && filters.locations.length > 0) {
      request_body.sectors = filters.locations;
    }

    if (
      filters &&
      (filters.level_min || filters.level_min === 0) &&
      filters.level_max
    ) {
      request_body.start_level_percentage =
        filters.level_min !== '' ? filters.level_min : undefined;

      request_body.end_level_percentage =
        filters.level_max !== '' ? filters.level_max : undefined;
    }

    if (filters && filters.just_collected) {
      request_body.just_collected = true;
    }

    if (filters && filters.types) {
      request_body.types = filters.types.length > 0 ? filters.types : undefined;
    }

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_Waterlevel_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const data = res.data;

        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          if (
            data[i]._id !== 'Biogradable' &&
            data[i]._id !== 'Non-Biogradable'
          ) {
            labels.push(data[i]._id);
            chart_data.push(Math.round(data[i].total_level * 100) / 100);
          }
        }

        let customLabels = labels.map(
          (label, index) => `${label}: (${chart_data[index]}L)`,
        );

        this.setState({
          isLoading: false,
          data: {
            labels: customLabels,
            datasets: [
              {
                label: 'Levels per day (L)',
                data: chart_data,
                backgroundColor: colors,
              },
            ],
          },
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  render() {
    return (
      <div className="chart-container">
        <div className="card">
          <div className="card-header">
            <h3>Max Level Per Type</h3>
          </div>
          <div className="card-body" style={{height: 280}}>
            {this.state.isLoading ? (
              <LoadingSpinner />
            ) : (
              <Pie data={this.state.data} options={options} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {filters} = state.Filters;
  return {filters};
};

export default connect(mapStateToProps)(WaterLevelPerType);
