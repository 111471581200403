import React from 'react';
import Table from '../Table/Table';
import './styles.css';
import {getStorage} from '../../utils/storage';
import {ENDPOINT} from '../../constants/api';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';
import {connect} from 'react-redux';
import {CSVLink} from 'react-csv';
import filtersData from '../../utils/filtersData';
import {ImageView} from '../ImageView';
import socket from '../../utils/socketIO';

let copyData = [];

class ElectricityFlow extends React.Component {
  state = {
    data: [],
    page: 1,
    limit: 10,
    selected_data: {},
    user: {},
    totalPageCount: 0,
    csvData: [],
    filepath: '',
    isReaTime: false,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);

    socket.on('vehicles', () => {
      this.getData(false);
    });
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const {filters, valid, invalid} = this.props;
    const {page, limit} = this.state;

    let request_body = filtersData({
      page,
      limit,
      filters,
    });

    if (valid) {
      request_body.valid_plate = true;
    }

    if (invalid) {
      request_body.valid_plate = false;
    }

    request_body.page = page;
    request_body.limit = limit;

    return request_body;
  }

  getData(isLoading, params) {
    const {user} = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnFilters();

    if (params?.search_text) {
      request_body.search = params.search_text;
    }

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_electricity_status_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data, count} = res.data;

        console.log('====================================');
        console.log(data);
        console.log('====================================');
        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        copyData = data.slice(0);
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      },
    );
  }

  handleOpenModal(modal, selected_data) {
    if (modal === 'showImageModal') {
      this.setState({
        [modal]: true,
        filepath: selected_data.filepath,
        selected_data,
      });
      return;
    }

    this.setState({
      [modal]: true,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({search_text});

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({data: array});
  }

  handlePressSearch() {
    const {search_text} = this.state;

    if (search_text === '') return;

    this.getData(true, {
      search_text,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: 'Station Coverage',
        key: 'station_coverage',
      },
      {
        title: 'Station',
        key: 'type',
      },
      {
        title: 'Station',
        key: 'imei',
      },
      {
        title: 'IMEI',
        key: 'imei',
      },
      {
        title: 'Date',
        key: 'createdAt',
        formatTime: 'lll',
        isMoment: true,
      },
    ];

    return headers;
  }

  handleRealTime(e) {
    this.setState({
      isReaTime: e.target.checked,
    });

    if (e.target.checked) {
      socket.connect();
    } else {
      socket.disconnect();
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <Table
            data={this.state.data}
            no_bordered
            isSearch
            style={{marginBottom: 0}}
            totalPageCount={this.state.totalPageCount}
            page={this.state.page}
            search_text={this.state.search_text}
            handleSearch={this.handleSearch.bind(this)}
            addSearchButton
            handlePressSearch={this.handlePressSearch.bind(this)}
            limit={this.state.limit}
            isLoading={this.state.isLoading}
            handlePagination={this.handlePagination.bind(this)}
            headers={this.returnTableHeaders()}
            rowPress={item =>
              this.handleOpenModal('showVehicleUpdateModal', item)
            }
            showRealTime
            isReaTime={this.state.isReaTime}
            checkboxLabel="Real Time Update"
            handleRealTime={this.handleRealTime.bind(this)}
          />
        </div>
        <CSVLink
          ref="csvDownload"
          filename={'Water Tank' + new Date().getTime()}
          data={this.state.csvData}></CSVLink>
      </div>
    );
  }
}

const mapStateProps = state => {
  const {filters} = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateProps)(ElectricityFlow);
