import { io } from "socket.io-client";
import {ROOT} from '../constants/api';

const socket = io(ROOT, {
  transports: ['websocket'],
});

socket.connect();

export default socket;
