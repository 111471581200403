// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/ksez-image.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contact-container{\n min-height: 100vh;\n background: linear-gradient(\n    to bottom,\n    rgba(37, 57, 77, 0.8),\n    rgba(27, 38, 50)\n  ),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") fixed no-repeat;\n  width: 100%;\n  background-position: center;\n  background-size: cover;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.contact-container h3{\n    font-size: 40px;\n    color: var(--color-white);\n    margin-bottom: 6rem;\n}\n\n.contact-container .form-control{\n    min-height: 60px;\n}\n\n.contact-container .btn{\n    margin-top: 4rem;\n}\n\n@media (max-width:480px) {\n    .contact-container{\n        padding: 3rem\n    }\n}", "",{"version":3,"sources":["webpack://./src/app/components/LandingPage/ContactUs/styles.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB;;;;2DAIsD;EACrD,WAAW;EACX,2BAA2B;EAC3B,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI;IACJ;AACJ","sourcesContent":[".contact-container{\n min-height: 100vh;\n background: linear-gradient(\n    to bottom,\n    rgba(37, 57, 77, 0.8),\n    rgba(27, 38, 50)\n  ),url(../../../assets/ksez-image.png) fixed no-repeat;\n  width: 100%;\n  background-position: center;\n  background-size: cover;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.contact-container h3{\n    font-size: 40px;\n    color: var(--color-white);\n    margin-bottom: 6rem;\n}\n\n.contact-container .form-control{\n    min-height: 60px;\n}\n\n.contact-container .btn{\n    margin-top: 4rem;\n}\n\n@media (max-width:480px) {\n    .contact-container{\n        padding: 3rem\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
