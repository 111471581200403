import React from 'react';
import {Button} from '../Button';
import {Input, Select} from '../Input';
import validateEmail from '../../utils/validateEmail';

import './styles.css';
import {ENDPOINT} from '../../constants/api';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';
import {getStorage} from '../../utils/storage';
import {availables, statuses} from '../../constants/strings';

class NewThing extends React.Component {
  state = {
    email: '',
    password: '',
    error: {},
    isSubmitting: false,
    user: {},
    _id: '',
    device_id: '',
    serial_number: '',
    status: statuses[0],
    available: availables[0],
    network_id: '',
    imei: '',
    device_slaves: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    if (this.props._id && this.props._id !== '') {
      this.setState({
        ...this.props,
        status: {
          label: this.props.status,
          value: this.props.status,
        },
        available: {
          label: this.props.available + '',
          value: this.props.available + '',
        },
      });
    }
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  onChangeText(name, e) {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    this.setState({
      error,
      [name]: value,
    });
  }

  validateForm() {
    let {device_id, serial_number, error, imei} = this.state;

    if (device_id === '') {
      error.device_id = 'Device id is required';
    }

    if (serial_number === '') {
      error.serial_number = 'Serial number is required';
    }

    if (imei === '') {
      error.imei = 'IMEI is required';
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      device_id,
      serial_number,
      status,
      available,
      user,
      _id,
      error,
      network_id,
      imei,
      device_slaves,
    } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let url = ENDPOINT + '/add_device_info';

      let data = {
        device_id,
        serial_number,
        status: status.value,
        available: available.value,
        network_id,
        imei,
        device_slaves,
      };

      if (user.ref_account) {
        data.ref_account = user.ref_account;
      }

      if (user.ref_subaccount) {
        data.ref_subaccount = user.ref_subaccount;
      }

      if (_id !== '') {
        url = ENDPOINT + '/update_device_info';
        data.id = _id;
      }

      const options = {
        method: 'POST',
        url,
        data,
        headers: {'Content-Type': 'multipart/form-data'},
        headers: {
          authorization: 'Bearer ' + user.token,
        },
      };

      axios(options)
        .then(data => {
          this.setState({
            isSubmitting: false,
            serial_number: '',
            device_id: '',
            network_id: '',
            device_slaves: [],
          });

          toastMessage(
            'success',
            `Device was ${
              this.state._id !== '' ? 'updated' : 'created'
            } successfully`,
          );

          this.props.handleCloseModal();
          this.props.getDevices(true);
        })
        .catch(error => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage('error', error);
        });
    }
  };

  onAddDeviceSlave(item) {
    let {device_slaves} = this.state;

    device_slaves.push(item);

    this.setState({device_slaves});
  }

  onChangeSlaveInput(params) {
    let {name, value, index} = params,
      {device_slaves} = this.state;

    device_slaves[index][name] = value;

    this.setState({
      device_slaves,
    });
  }

  onRemoveSlave(index) {
    let {device_slaves} = this.state;

    device_slaves.splice(index, 1);

    this.setState({
      device_slaves,
    });
  }

  renderDeviceSlave(device, index) {
    return (
      <div className="card" style={{marginBottom: 15}}>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                label="Position"
                value={this.state.device_slaves[index]?.position}
                onChange={e =>
                  this.onChangeSlaveInput({
                    name: `position`,
                    value: e.target.value,
                    index,
                  })
                }
                error={this.state.error['position_' + index]}
              />
            </div>
            <div className="col-md-9">
              <Input
                label="Name"
                value={this.state.device_slaves[index]?.name}
                onChange={e =>
                  this.onChangeSlaveInput({
                    name: `name`,
                    value: e.target.value,
                    index,
                  })
                }
                error={this.state.error['name_' + index]}
              />
            </div>
            <div className="col-md-2" style={{marginTop: 26}}>
              <Button
                icon="bx-trash"
                className="btn-danger"
                onPress={() => this.onRemoveSlave(index)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                label="Device ID:"
                required
                className="form-control-lg"
                value={this.state.device_id}
                onChange={e => this.onChangeText('device_id', e)}
                error={this.state.error.device_id}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Serial Number:"
                required
                className="form-control-lg"
                value={this.state.serial_number}
                onChange={e => this.onChangeText('serial_number', e)}
                error={this.state.error.serial_number}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="IMEI:"
                required
                className="form-control-lg"
                value={this.state.imei}
                onChange={e => this.onChangeText('imei', e)}
                error={this.state.error.imei}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Network ID:"
                required
                className="form-control-lg"
                value={this.state.network_id}
                onChange={e => this.onChangeText('network_id', e)}
                error={this.state.error.network_id}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={statuses}
                label="Status:"
                className="form-control-lg "
                value={this.state.status}
                onChange={e => this.onChangeText('status', e)}
                error={this.state.error.status}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={availables}
                label="Available:"
                className="form-control-lg "
                value={this.state.available}
                onChange={e => this.onChangeText('available', e)}
                error={this.state.error.available}
              />
            </div>
            <hr />
            <div className="col-md-12">
              {this.state.device_slaves.map((el, i) => {
                return this.renderDeviceSlave(el, i);
              })}
              <Button
                className="btn-primary"
                text="Add Device Slave"
                onPress={() =>
                  this.onAddDeviceSlave({
                    position: '',
                    name: '',
                  })
                }
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default NewThing;
