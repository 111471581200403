import React from 'react';
import './styles.css';
import introImg from '../../../assets/dashboard-intro.png';
import {Button} from '../../Button';
import Fade from 'react-reveal/Fade';

const Introduction = () => {
  return (
    <div className="introduction-container">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-6">
            <div className="intro-text-container">
              <div>
                <Fade bottom>
                  <h1>
                    <span className="intro-name">KSEZ</span> Smart
                    <br /> Sensor Platform
                  </h1>
                </Fade>
                <Fade bottom>
                  <p>
                    AI-powered solution for enabling real-time monitoring of
                    critical aspects in economic zones, focusing on water
                    levels, air quality, and electricity cut-offs, as well as
                    automatic vehicle number plate recognition for seamless
                    check-ins and checkouts.{' '}
                  </p>
                </Fade>

                <div className="row introduction-btn gap-2">
                  <div className="col-sm-6 col-md-4">
                    <Fade bottom>
                      <div>
                        <Button
                          onPress={() => {
                            window.location.href = '/login';
                          }}
                          text="Login"
                          className="btn-primary btn-lg btn-block btn-xl"
                        />
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-4">
                    <Fade bottom>
                      <div>
                        <Button
                          text="Contact Us"
                          className="btn-bordered color-white btn-lg btn-block btn-xl intro-middle-btn"
                          onPress={() => (window.location.href = '#contactus')}
                        />
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Fade right>
            <div className="col-md-6 introduction-img">
              <img src={introImg} />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
