import React from 'react';
import './styles.css';
import Fade from 'react-reveal/Fade';

const Title = props => {
  return (
    <Fade bottom>
      <div className="title-container">
        <p>{props.smText}</p>
        <h3>{props.text}</h3>
      </div>
    </Fade>
  );
};

export default Title;
