export const APP_NAME = 'cleankigali';
export const REACT_APP_MAPS_API_KEY = 'AIzaSyBQGGf1Y_JkJ3sT2oHwEC59Apt0NQVD-Z0';

export const account_roles = {
  admin_account: [
    {
      value: 'admin',
      label: 'Admin',
    },
  ],
  user_account: [
    {
      value: 'admin',
      label: 'Admin',
    },
    {
      value: 'caretaker',
      label: 'Caretaker',
    },
  ],
};

export const statuses = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const availables = [
  {
    label: 'true',
    value: 'true',
  },
  {
    label: 'false',
    value: 'false',
  },
];

export const thing_types = [
  {
    label: 'Traffic',
    value: 'Traffic',
  },
  {
    label: 'Tank',
    value: 'Tank',
  },
  {
    label: 'Station',
    value: 'Station',
  },
  {
    label: 'Air',
    value: 'Air',
  },
];

export const thing_status = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Non Active',
    value: 'non_active',
  },
];

export const colors = (opacity = 1) => [
  'rgba(12, 151, 244, ' + opacity + ')',
  'rgba(60,209,136, ' + opacity + ')',
  'rgba(255,127,93, ' + opacity + ')',
  'rgba(105,124,254, ' + opacity + ')',
  'rgba(239,175,77, ' + opacity + ')',
  'rgba(142, 68, 173, ' + opacity + ')',
];


export const days = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday',
};

export const months = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};