import axios from 'axios';
import React from 'react';
import {ENDPOINT} from '../../constants/api';
import toastMessage from '../../utils/toastMessage';
import Table from '../Table/Table';
import './styles.css';
import {getStorage} from '../../utils/storage';
import {connect} from 'react-redux';
import exportPDF from '../../utils/exportPDF';
import {CSVLink} from 'react-csv';
import {Modal} from '../Modal';
import SmsInfo from './SmsInfo';
import {ThingInfo} from '../Thing';

let copySmsLogs = [];

class SmsLogs extends React.Component {
  state = {
    data: [],
    isLoading: true,
    user: {},
    page: 1,
    limit: 10,
    error: {},
    csvData: [],
    selected_sms: {},
    delete_password: '',
    selected_thing: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getSmsLogs(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getSmsLogs(isLoading) {
    const {user, page, limit} = this.state;

    this.setState({
      isLoading,
    });

    let body = {
      page,
      limit,
    };

    let url = ENDPOINT + '/get_sms_email_logs_info';

    const options = {
      method: 'POST',
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        let {data, count} = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        if (data.length !== 0) {
          copySmsLogs = data.slice(0);
        }
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });

        toastMessage('error', error);
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getSmsLogs(true);
      },
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;
    let array = [];

    this.setState({
      search_text,
    });

    for (let i = 0; i < copySmsLogs.length; i++) {
      if (
        JSON.stringify(copySmsLogs[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copySmsLogs[i]);
      }
    }

    this.setState({
      data: array,
    });
  }

  downloadExcel = () => {
    const {user} = this.state;

    this.setState({
      isLoading: true,
    });

    let url = ENDPOINT + '/get_sms_email_logs_info';

    const options = {
      method: 'POST',
      url,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };
    axios(options)
      .then(res => {
        let {data} = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState(
          {
            isLoading: false,
            csvData: data,
          },
          () => {
            this.refs.csvDownload?.link.click();
          },
        );
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });

        toastMessage('error', error);
      });
  };

  downloadPDF = () => {
    const headers = this.returnTableHeaders();

    const {user} = this.state;
    const {filters} = this.props;

    this.setState({
      isLoading: true,
    });

    let url = ENDPOINT + '/get_sms_email_logs_info';

    const options = {
      method: 'POST',
      url,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        let {data} = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState({
          isLoading: false,
        });

        exportPDF('Sms Logs', headers, data);
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });

        toastMessage('error', error);
      });
  };

  handleShowModal(modal, modalTitle, selected_item = {}) {
    let {selected_thing, selected_sms} = this.state;
    if (modalTitle === 'Sms Info') {
      selected_sms = selected_item;
    }

    if (modalTitle === 'Thing Info') {
      selected_thing = selected_item.thing_id;
    }

    this.setState({
      [modal]: true,
      modalTitle,
      selected_sms,
      selected_thing,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: 'Object ID',
        key: 'thing_id.thing_id',
        type: 'link',
        onPress: this.handleShowModal.bind(
          this,
          'showThingModal',
          'Thing Info',
        ),
      },
      {
        title: 'Message',
        key: 'payload.subject',
      },
      {
        title: 'Type',
        key: 'type',
      },
      {
        title: 'Date',
        key: 'date',
        isMoment: true,
        formatTime: 'lll',
      },
    ];

    return headers;
  }

  render() {
    return (
      <div>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={this.handleShowModal.bind(this, 'showModal', 'Sms Info')}
          filters={[
            {
              type: 'export',
              title: 'Export',
              button_type: 'dropdown',
              icon: 'bx bxs-download',
              options: [
                {
                  name: 'PDF',
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: 'CSV',
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, 'showModal')}
          show={this.state.showModal}
          title="Sms Log">
          <SmsInfo
            {...this.state.selected_sms}
            {...this.state.selected_sms.payload}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, 'showThingModal')}
          show={this.state.showThingModal}
          title={this.state.modalTitle}
          size="lg">
          <ThingInfo
            {...this.state.selected_thing}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              'showThingModal',
            )}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={'Sms Logs' + new Date().getTime()}
          data={this.state.csvData}></CSVLink>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {filters} = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(SmsLogs);
