import React from 'react';
import './styles.css';
import {Title} from '../Title';
import airQualityImg from '../../../assets/air-quality.png';
import Fade from 'react-reveal/Fade';

const AirQuality = () => {
  return (
    <div className="air-quality-container">
      <div className="container">
        <div className="air-quality-img-container">
          <Fade bottom>
            <img src={airQualityImg} />
          </Fade>
        </div>
        <Title text="Air Quality Monitoring" smTitle="Features" />
        <Fade bottom>
          <p className="description">
            Advanced air quality monitoring system delivering real-time data and
            analytics on air quality levels and enabling easy identification
            potential pollution risks to take proactive measures. With this
            cutting-edge technology, you can ensure a safe and healthy working
            environment for all tenants in the zone.
          </p>
        </Fade>
      </div>
    </div>
  );
};

export default AirQuality;
