import React from 'react';
import {Footer} from '../components/LandingPage/Footer';
import {NavBar} from '../components/LandingPage/NavBar';
import {Introduction} from '../components/LandingPage/Introduction';
import {Partner} from '../components/LandingPage/Partner';
import {WaterLevel} from '../components/LandingPage/WaterLevel';
import {VehicleRecognition} from '../components/LandingPage/VehicleRecognition';
import {AirQuality} from '../components/LandingPage/AirQuality';
import {Power} from '../components/LandingPage/Power';
import ContactUs from '../components/LandingPage/ContactUs/ContactUs';
import {FloatButton} from '../components/LandingPage/FloatButton';
class LandingPageScreen extends React.Component {
  render() {
    return (
      <div className="app">
        <NavBar />
        <div className="app-containers">
          <section className="section" id="home">
            <Introduction />
          </section>
          <section className="section" id="partners">
            <Partner />
          </section>
          <section className="section" id="features">
            <WaterLevel />
          </section>
          <section className="section" id="vehicle-recognition">
            <VehicleRecognition />
          </section>
          <section id="air-power">
            <div className="row" style={{marginRight: 0}}>
              <div
                className="col-md-6"
                style={{paddingLeft: 0, paddingRight: 0}}>
                <AirQuality />
              </div>
              <div
                className="col-md-6"
                style={{paddingLeft: 0, paddingRight: 0}}>
                <Power />
              </div>
            </div>
          </section>
          <section className="section" id="contactus">
            <ContactUs />
          </section>
          <Footer />
        </div>
        <FloatButton />
      </div>
    );
  }
}

export default LandingPageScreen;
