export const menus = [
  {
    group: 'Home',
    icon: 'bx-home',
    route: '/dashboard/home',
  },
  {
    group: 'Analytics',
    icon: 'bxs-dashboard',
    items: [
      {
        name: 'Water Levels',
        route: '/dashboard/analytics/Water_activities',
        group: 'Analytics',
      },
      {
        name: 'Traffic Insights',
        route: '/dashboard/analytics/Water_activities',
        group: 'Analytics',
      },
      {
        name: 'Electricity Flow',
        route: '/dashboard/analytics/Water_activities',
        group: 'Analytics',
      },
      {
        name: 'Air Quality',
        route: '/dashboard/analytics/Water_activities',
        group: 'Analytics',
      },
      {
        name: 'Sensor Activities',
        route: '/dashboard/analytics/sensor_activities',
        group: 'Analytics',
      },
    ],

    show: true,
  },
  {
    group: 'Series Reports',
    icon: 'bx-grid',
    items: [
      {
        name: 'Water Levels',
        route: '/dashboard/series_reports/Water_levels',
        group: 'Series Reports',
      },
      {
        name: 'Vehicle Recognition',
        route: '/dashboard/series_reports/check_in-checkout',
        group: 'Series Reports',
      },
      {
        name: 'Electricity Flow',
        route: '/dashboard/series_reports/electricity_cut-off',
        group: 'Series Reports',
      },
      {
        name: 'Air Quality',
        route: '/dashboard/series_reports/air_quality',
        group: 'Series Reports',
      },
    ],
    show: true,
  },
  {
    group: 'Alerts & Incidents',
    icon: 'bx-error',
    items: [
      {
        name: 'Alerts Levels',
        route: '/dashboard/alerts/alerts_levels',
        group: 'Alerts',
      },
      {
        name: 'Alerts Sensors',
        route: '/dashboard/alerts/alerts_sensors',
        group: 'Alerts',
      },
    ],
    show: true,
  },
  {
    group: 'Objects & Devices',
    route: '/dashboard/object_devices',
    icon: 'bxs-cylinder',
    role: 'admin',
  },
  {
    group: 'Accounts',
    route: '/dashboard/accounts',
    icon: 'bx-group',
  },
];
