import React from 'react';
import './styles.css';

const CardCount = props => {
  return (
    <div className="card card-count">
      <div className="card-body">
        <div className="card-count-content">
          <div className="card-count-cool" style={props.bg}>
            <i class={`bx ${props.icon}`}></i>
          </div>
          <div style={{flex: 1, marginLeft: 100}}>
            <div>
              {props.total_object ? (
                <div className="totals">
                  {props?.total_object?.map((item, i) => {
                    return (
                      <div key={i}>
                        <span className="total_n_ob">{item.total}</span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="total">{props.total}</p>
              )}
            </div>
            <div>
              <h1>{props.title}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCount;
