import React from 'react';
import {Bar} from 'react-chartjs-2';
import {LoadingSpinner} from '../LoadingSpinner';
import './styles.css';
import {connect} from 'react-redux';
import {ENDPOINT} from '../../constants/api';
import {getStorage} from '../../utils/storage';
import toastMessage from '../../utils/toastMessage';
import axios from 'axios';
import filtersData from '../../utils/filtersData';
class SensorMonitoring extends React.Component {
  state = {
    data: [],
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const {user} = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnFilters();

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_device_trend_status_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        let data = res.data;

        console.log('====================Monitoring test================');
        console.log({get_device_trend_status_info: data});
        console.log('====================================');

        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          labels.push(data[i].down_date);
          chart_data.push(data[i].down_percentage);
        }

        this.setState({
          isLoading: false,
          data: {
            labels,
            datasets: [
              {
                label: 'Offline Sensors (%)',
                data: chart_data,
                backgroundColor: '#f02',
                borderColor: 'rgba(0,0,0,1)',
              },
            ],
          },
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  returnFilters() {
    const {filters} = this.props;

    let request_body = filtersData({filters});

    request_body.target = 'all';

    return request_body;
  }

  render() {
    return (
      <div className="card sensor-monitoring">
        <div className="card-header">
          <h3>Sensors Offline Monitoring</h3>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <Bar
              data={this.state.data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                },
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {filters} = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(SensorMonitoring);
