import React from 'react';
import './styles.css';
import {Title} from '../Title';
import {Input} from '../../Input';
import {Button} from '../../Button';
import validateEmail from '../../../utils/validateEmail';
import {ENDPOINT} from '../../../constants/api';
import toastMessage from './../../../utils/toastMessage';
import axios from 'axios';
import Fade from 'react-reveal/Fade';

class ContactUs extends React.Component {
  state = {
    name: '',
    email: '',
    message: '',
    isSubmitting: false,
    error: {},
  };

  onChangeText(name, e) {
    let {error} = this.state;

    delete error[name];

    this.setState({
      [name]: e.target.value,
      error,
    });
  }

  validateForm() {
    let {error, name, email, message} = this.state;

    if (name === '') {
      error.name = 'Name is required';
    }

    if (email === '') {
      error.email = 'Email address is required';
    } else if (!validateEmail(email)) {
      error.email = 'Invalid Email address';
    }

    if (message === '') {
      error.message = 'Message is required';
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    this.validateForm();

    const {error, name, message, email} = this.state;

    if (Object.keys(error).length === 0) {
      const options = {
        method: 'POST',
        url: ENDPOINT + '',
        data: {
          name,
          email,
          message,
        },
      };

      axios(options)
        .then(data => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            'success',
            'Thank you for contacting us, will respond you very soon',
          );
        })
        .catch(error => {
          this.setState({isSubmitting: false});

          toastMessage('error', error);
        });
    }
  };

  render() {
    return (
      <div className="contact-container">
        <Fade bottom>
          <div className="container ">
            <div className="row d-flex justify-content-center">
              <div className="col-md-5">
                <center>
                  <Title text="Contact Us" />
                </center>
                <Input
                  placeholder="Name"
                  required
                  error={this.state.error.name}
                  onChange={e => this.onChangeText('name', e)}
                />
                <Input
                  placeholder="Email Address"
                  required
                  error={this.state.error.email}
                  onChange={e => this.onChangeText('email', e)}
                />
                <textarea
                  rows={5}
                  className="form-control"
                  placeholder="Message"
                  onChange={e => this.onChangeText('message', e)}></textarea>
                {this.state.error.message && (
                  <span className="text-danger">
                    {this.state.error.message}
                  </span>
                )}
                <center>
                  <Button
                    className="btn-primary btn-lg btn-xl"
                    text="Submit"
                    onPress={this.onSubmit.bind(this)}
                  />
                </center>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}

export default ContactUs;
