import React from 'react';
import './styles.css';
import {Button} from '../Button';
import Input from '../Input/Input';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';
import {ENDPOINT} from '../../constants/api';
import {setStorage} from '../../utils/storage';
import LOGO from '../../assets/logo.png';

const roles = ['SuperAdmin', 'User'];

class Login extends React.Component {
  state = {
    activeRole: 'User',
    username: '',
    password: '',
    isSubmitting: false,
    error: {},
    password_checker: {},
  };

  changeRole(activeRole) {
    this.setState({
      activeRole,
    });
  }

  onChangeText(name, e) {
    let {error} = this.state,
      text = e.target.value;

    delete error[name];

    this.setState({
      [name]: text,
      error,
    });
  }

  validateForm() {
    let {username, password, error} = this.state;

    if (username === '') {
      error.username = 'Email or phone number is required';
    }

    if (password === '') {
      error.password = 'Password is required';
    }

    this.setState({
      error,
    });
  }

  onSubmit = async e => {
    e.preventDefault();
    await this.validateForm();

    let {username, password, error, activeRole} = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({isSubmitting: true});

      let url = '/authentication_user',
        data = {email: username, password};

      if (activeRole === 'SuperAdmin') {
        url = '/authentication_superadmin';
      }

      const options = {
        method: 'POST',
        url: `${ENDPOINT}${url}`,
        data,
      };

      axios(options)
        .then(data => {
          this.onSuccess(data.data);
        })
        .catch(error => {
          toastMessage('error', error);

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  onSuccess = async user => {
    //sve storage
    await setStorage(user);

    if (user.first_time) {
      this.sendOTP(user.username);

      return;
    }

    this.setState({
      isSubmitting: false,
    });

    window.location.href = '/dashboard/home';
  };

  sendOTP(username) {
    const options = {
      method: 'POST',
      url: ENDPOINT + '/forgot_password_user',
      data: {
        email: username,
      },
    };

    axios(options)
      .then(res => {
        this.setState({
          isSubmitting: false,
        });

        window.location.href = '/reset_password/' + username;
      })
      .catch(error => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage('error', error);
      });
  }

  render() {
    return (
      <div className="login-container">
        <div className="container">
          <div
            className="row align-items-center justify-content-center gap-5"
            style={{height: '80vh'}}>
            <center
              className="form-logo-container"
              onClick={() => (window.location.href = '/')}
              style={{cursor: 'pointer'}}>
              <img src={LOGO} />
              <span>
                KSEZ <b>IoT</b> Platform
              </span>
            </center>
            <div className="col-12 col-md-5">
              <div className="form-container card form-card">
                <div className="card-body">
                  <h3 className="md-2 mb-3" style={{fontSize: 15}}>
                    <b>
                      Login{' '}
                      {this.state.activeRole === 'SuperAdmin'
                        ? ' as super admin'
                        : ''}
                    </b>
                  </h3>
                  <form>
                    <Input
                      placeholder="Email address or Phone number"
                      value={this.state.username}
                      onChange={e => this.onChangeText('username', e)}
                      error={this.state.error.username}
                      className="form-control-lg"
                    />
                    <Input
                      placeholder="Password"
                      type="password"
                      value={this.state.password}
                      onChange={e => this.onChangeText('password', e)}
                      error={this.state.error.password}
                      className="form-control-lg"
                      password_checker={this.state.password_checker}
                    />
                    <Button
                      text="Login"
                      className="btn-lg btn-primary w-100"
                      isSubmitting={this.state.isSubmitting}
                      onPress={this.onSubmit.bind(this)}
                    />
                  </form>
                </div>
                <center className="card-footer">
                  <a className="forgot-password" href="/forgot_password">
                    <b>Can't login?</b>
                  </a>
                  <span className="separator">-</span>
                  {roles.map((role, r) => {
                    if (this.state.activeRole !== role) {
                      return (
                        <>
                          <div key={r} className="role">
                            <span
                              onClick={this.changeRole.bind(this, role)}
                              className="text-primary">
                              <b>Login as {role}</b>
                            </span>
                          </div>
                          <br />
                        </>
                      );
                    }
                  })}
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
