import React from 'react';
import './styles.css';
import {Checkbox, Input} from '../Input';
import {onFilter} from '../../action/Filters';
import {connect} from 'react-redux';

let copyTypes = [];
class WaterType extends React.Component {
  state = {
    recent_type: [
      {
        name: 'General',
      },
      {
        name: 'Emergency',
      },
      {
        name: 'Other',
      },
    ],
    selected_type: [],
  };

  componentDidMount() {
    copyTypes = this.state.recent_type.slice(0);

    this.setState({
      selected_type:
        this.props.filters && this.props.filters.types
          ? this.props.filters.types
          : [],
    });
  }

  handleCheck(type) {
    let selected_type = this.state.selected_type;

    let index = selected_type.indexOf(type);

    if (index !== -1) {
      selected_type.splice(index, 1);
    } else {
      selected_type.push(type);
    }

    this.setState(
      {
        selected_type,
      },
      () => {
        this.props.dispatch(onFilter({types: selected_type}));
      },
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    let array = [];

    this.setState({
      search_text,
    });

    for (let i = 0; i < copyTypes.length; i++) {
      if (
        copyTypes[i].name.toUpperCase().indexOf(search_text.toUpperCase()) !==
        -1
      ) {
        array.push(copyTypes[i]);
      }
    }

    this.setState({
      recent_type: array,
    });
  }

  onResetCheck() {
    this.props.dispatch(onFilter({types: []}));

    this.setState({selected_type: [], search_text: ''});
  }

  render() {
    return (
      <form>
        <div className="card cdropdown-container">
          <div className="card-body">
            <Input
              placeholder="Search trash type..."
              className="form-control-sm"
              value={this.state.search_text}
              onChange={this.handleSearch.bind(this)}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <h1 className="separator-title">Types</h1>
              {this.props.filters &&
                this.props.filters.types &&
                this.props.filters.types.length > 0 && (
                  <a
                    href="#"
                    className="text-danger"
                    onClick={this.onResetCheck.bind(this)}>
                    <b>{`Clear (${this.props.filters.types.length})`}</b>
                  </a>
                )}
            </div>
            {this.state.recent_type.map((item, i) => {
              return (
                <div className="cdropdown-item" key={i}>
                  <Checkbox
                    name={item.name}
                    handleCheck={this.handleCheck.bind(this, item.name)}
                    checked={this.state.selected_type.includes(item.name)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  const {filters} = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(WaterType);
