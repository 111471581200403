import React from "react";
import "./styles.css";
import Fade from "react-reveal/Fade";

const Footer = () => {
  const socials = [
    {
      name: 'Youtube',
      icon: 'bxl-youtube',
      link: 'https://www.youtube.com/channel/UCod_oe8CXw8G3v0R3nlNdFQ',
    },
    {
      name: 'Twitter',
      icon: 'bxl-twitter',
      link: 'https://twitter.com/CityofKigali',
    },
    {
      name: 'Flickr',
      icon: 'bxl-facebook',
      link: 'https://www.flickr.com/photos/city_of_kigali/albums/',
    },
  ];
  return (
    <div className="footer">
      <div className="container">
        <Fade bottom>
          <div className="l">
            <span>&copy; {new Date().getFullYear()} All right reserved</span>
          </div>
          <div className="socials">
            {/* {socials.map((item, i) => {
              return (
                <div className="social-item" key={i}>
                  <a href={item.link} target="_blank">
                    <i className={`bx ${item.icon}`} />
                  </a>
                </div>
              );
            })} */}
          </div>
          <div className="powered">
            <span>
              Powered by{' '}
              <a href="https://www.huzalabs.com/" target="_blank">
                <span className="text-primary">Huza</span>Labs
              </a>
            </span>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Footer;
