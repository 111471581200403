import React from 'react';
import Table from '../Table/Table';
import './styles.css';
import {getStorage} from '../../utils/storage';
import {ENDPOINT} from '../../constants/api';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';
import {Modal} from '../Modal';
import {ThingInfo} from '../Thing';
import {connect} from 'react-redux';
import exportPDF from '../../utils/exportPDF';
import {CSVLink} from 'react-csv';
import filtersData from '../../utils/filtersData';
import {DeviceInfo} from '../Devices';

let copyData = [];

class WaterTank extends React.Component {
  state = {
    data: [],
    page: 1,
    limit: 10,
    selected_data: {},
    user: {},
    totalPageCount: 0,
    csvData: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const {filters} = this.props;
    const {page, limit} = this.state;

    let request_body = filtersData(filters);

    request_body.tag = 'all';
    request_body.page = page;
    request_body.limit = limit;

    console.log('====================================');
    console.log({request_body});
    console.log('====================================');

    return request_body;
  }

  getData(isLoading) {
    const {user} = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnFilters();

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_level_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data, count} = res.data;

        let results = [];

        console.log('====================================');
        console.log({data});
        console.log('====================================');

        for (let el of data) {
          results.push({
            ...el.last,
            thing: el?.thing?.length > 0 ? el.thing[0] : {},
            device: el?.device?.length > 0 ? el.device[0] : {},
          });
        }

        console.log('====================================');
        console.log({results});
        console.log('====================================');

        this.setState({
          data: results,
          isLoading: false,
          totalPageCount: count,
        });

        copyData = results.slice(0);
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      },
    );
  }

  handleOpenModal(modal, selected_data) {
    console.log('====================================');
    console.log({selected_data});
    console.log('====================================');
    this.setState({
      [modal]: true,
      selected_data:
        modal === 'showDeviceModal'
          ? selected_data.device
          : modal === 'showThingModal'
          ? selected_data.thing
          : {},
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({search_text});

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({data: array});
  }

  returnTableHeaders() {
    let headers = [
      {
        title: 'Level',
        key: 'level_percentage',
        type: 'level',
      },
      {
        title: 'Level in Litter',
        key: 'level',
        type: 'litter',
      },
      {
        title: 'Updated At',
        key: 'updatedAt',
        isMoment: true,
        formatTime: 'lll',
      },
      {
        title: 'Location',
        key: 'thing.address.geolocation',
      },
      {
        title: 'Object ID',
        key: 'thing.thing_id',
        type: 'link',
        onPress: this.handleOpenModal.bind(this, 'showThingModal'),
      },
      {
        title: 'Type',
        key: 'thing.type',
      },
      {
        title: 'Device',
        key: 'device.device_id',
        type: 'link',
        onPress: this.handleOpenModal.bind(this, 'showDeviceModal'),
      },
    ];

    return headers;
  }

  downloadExcel() {
    const {user} = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = this.returnFilters();

    request_body.status = this.props.status;

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_level_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const data = res.data;

        this.setState(
          {
            // csvData: ,
            isLoading: false,
          },
          () => {
            this.refs.csvDownload?.link.click();
          },
        );
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  downloadPDF() {
    const headers = this.returnTableHeaders();

    const {user} = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = this.returnFilters();

    request_body.status = this.props.status;

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_level_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const data = res.data;

        this.setState({
          isLoading: false,
        });

        exportPDF('Water Tank', headers, data);
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <Table
            data={this.state.data}
            no_bordered
            isSearch
            style={{marginBottom: 0}}
            totalPageCount={this.state.totalPageCount}
            page={this.state.page}
            search_text={this.state.search_text}
            handleSearch={this.handleSearch.bind(this)}
            limit={this.state.limit}
            isLoading={this.state.isLoading}
            handlePagination={this.handlePagination.bind(this)}
            headers={this.returnTableHeaders()}
            filters={[
              {
                type: 'export',
                title: 'Export',
                button_type: 'dropdown',
                icon: 'bx bxs-download',
                options: [
                  {
                    name: 'PDF',
                    onPress: this.downloadPDF.bind(this),
                  },
                  {
                    name: 'CSV',
                    onPress: this.downloadExcel.bind(this),
                  },
                ],
              },
            ]}
          />
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, 'showThingModal')}
          show={this.state.showThingModal}
          title={'Info'}
          size="lg">
          <ThingInfo
            {...this.state.selected_data}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              'showThingModal',
            )}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, 'showDeviceModal')}
          show={this.state.showDeviceModal}
          title={'Device Info'}
          size="lg">
          <DeviceInfo
            {...this.state.selected_data}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              'showDeviceModal',
            )}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={'Water Tank' + new Date().getTime()}
          data={this.state.csvData}></CSVLink>
      </div>
    );
  }
}

const mapStateProps = state => {
  const {filters} = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateProps)(WaterTank);
