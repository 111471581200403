import React from 'react';
import './styles.css';
import {Button} from '../Button';
import {Input} from '../Input';
import LOGO from '../../assets/logo.png';
import {ENDPOINT} from '../../constants/api';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';

class ForgotPassword extends React.Component {
  state = {
    username: '',
    error: {},
    isSubmitting: false,
  };

  onChangeText(name, e) {
    let {error} = this.state;
    delete error[name];
    this.setState({
      error,
      [name]: e.target.value,
    });
  }

  validateForm() {
    let {username, error} = this.state;

    if (username === '') {
      error.username = 'Email address or phone number is required';
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {error, username} = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      const options = {
        method: 'POST',
        url: ENDPOINT + '/forgot_password_user',
        data: {
          email: username,
        },
      };

      axios(options)
        .then(res => {
          this.setState({
            isSubmitting: false,
          });

          window.location.href = '/reset_password/' + username;
        })
        .catch(error => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage('error', error);
        });
    }
  };

  render() {
    return (
      <div className="container">
        <div
          className="auth-form-container row align-items-center justify-content-center gap-5"
          style={{height: '90vh', overflowY: 'hidden'}}>
          <center className="form-logo-container">
            <img src={LOGO} />
            <span>
              KSEZ <b>IoT</b> Platform
            </span>
          </center>
          <div className="col-12 col-md-5">
            <div className="form-container card form-card">
              <div className="card-body">
                <h3>
                  <b>Forgot password</b>
                </h3>
                <p className="text-disabled mb-4">
                  We'll sent you OTP code to reset your password
                </p>
                <form>
                  <Input
                    placeholder="Email address"
                    value={this.state.username}
                    onChange={e => this.onChangeText('username', e)}
                    className="form-control-lg mb-4"
                    required
                  />
                  <Button
                    text="Submit"
                    onPress={this.onSubmit.bind(this)}
                    className="btn-lg btn-primary w-100"
                    isSubmitting={this.state.isSubmitting}
                  />
                </form>
              </div>
              <center className="card-footer">
                <a href="/login">
                  <b>Go to login</b>
                </a>
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
