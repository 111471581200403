import React from 'react';
import './styles.css';
import {Title} from '../Title';
import powerImg from '../../../assets/electricity.png';
import Fade from 'react-reveal/Fade';

const Power = () => {
  return (
    <div className="power-container">
      <div className="power-img-container">
        <Fade bottom>
          <img src={powerImg} />
        </Fade>
      </div>
      <Title text="Power Cut Monitoring" smTitle="Features" />
      <Fade bottom>
        <p className="description">
          State-of-the-art system for monitoring electricity cut-offs in Kigali
          Special Economic Zones, providing real-time alerts on electricity
          status. This gives the zone management complete visibility into power
          supply disruptions. Hence the ability to take proactive measures to
          minimize downtime and ensure uninterrupted business operations.
        </p>
      </Fade>
    </div>
  );
};

export default Power;
