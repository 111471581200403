import React from 'react';
import filtersData from '../../utils/filtersData';
import returnChartList from './utils/returnChartList';
import toastMessage from '../../utils/toastMessage';
import axios from 'axios';
import {ENDPOINT} from '../../constants/api';
import {Doughnut} from 'react-chartjs-2';
import {LoadingSpinner} from '../LoadingSpinner';
import {numberWithCommas} from '../../utils/formatnNumber';
import {colors} from '../../constants/strings';
import {getStorage} from '../../utils/storage';

const options = {
  legend: {
    position: 'bottom',
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
};

class WaterUsedPerTank extends React.Component {
  state = {
    isLoading: true,
    water_used: {},
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({user});
    this.getData(true);
  };

  returnFilters() {
    const {filters} = this.props;

    let request_body = filtersData({filters});

    request_body.target = 'all';

    return request_body;
  }

  getData(isLoading) {
    const {user} = this.state;

    let request_body = this.returnFilters();

    request_body.page = 1;
    request_body.limit = 5;
    request_body.tag = 'all';
    request_body.order = 'object';

    this.setState({
      isLoading,
    });

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_used_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data} = res.data;

        console.log('**** water used per tank...', data);

        let chart_data = [],
          labels = [],
          {top_five_water_used} = this.state;

        for (let i = 0; i < data.length; i++) {
          if (data[i].thing && data[i].thing.length > 0) {
            labels.push(
              `${data[i].thing[0].address.geolocation} (${data[
                i
              ].thing[0].type.toLowerCase()})`,
            );

            top_five_water_used.push({
              label: data[i].thing[0].thing_id,
              value: numberWithCommas(Math.round(data[i].start_level)),
            });

            let label = `${data[i].thing[0].address.geolocation} (${data[
              i
            ].thing[0].type.toLowerCase()})`;

            chart_data.push([label, Math.round(data[i].total_used)]);
          }
        }
        chart_data = chart_data.sort((a, b) => b[1] - a[1]);
        chart_data = chart_data.map(el => el[1]);

        let customLabels = labels.map(
          (label, index) => `${label}: ${numberWithCommas(chart_data[index])}`,
        );

        let water_used = {
          labels: customLabels,
          datasets: [
            {
              data: chart_data,
              backgroundColor: colors(1),
            },
          ],
        };

        this.setState({
          isLoading: false,
          water_used,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  render() {
    return (
      <div className="col-md-4">
        <div className="card card-analytics">
          <div className="card-header">
            <h3>Top 5 Water Used Per Tank </h3>
          </div>
          <div className="card-body">
            {this.state.isLoading ? (
              <LoadingSpinner />
            ) : (
              <div>
                <div style={{height: 180}}>
                  <Doughnut data={this.state.water_used} options={options} />
                </div>
                {returnChartList(
                  this.state.water_used.labels
                    ? this.state.water_used.labels
                    : [],
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default WaterUsedPerTank;
