import moment from 'moment';

export default ({
  page,
  limit,
  filters,
  startDateType = 'start_createdAt',
  endDateType = 'end_createdAt',
  tag,
}) => {
  let request_body = {};

  if (page) {
    request_body.page = page;
  }

  if (limit) {
    request_body.limit = limit;
  }

  if (tag) {
    request_body.tag = tag;
  }

  if (filters?.thing && filters.thing.length > 0) {
    let things = [];
    for (let el of filters.thing) {
      things.push(el.value);
    }
    request_body.thing = things;
  }

  if (filters?.locations && filters.locations.length > 0) {
    let locations = [];
    for (let el of filters.locations) {
      locations.push(el.thing_id);
    }
    request_body.thing_ids = locations;
  }

  if (
    filters &&
    (filters.level_min || filters.level_min === 0) &&
    filters.level_max
  ) {
    request_body.start_level_percentage =
      filters.level_min !== '' ? filters.level_min : undefined;

    request_body.end_level_percentage =
      filters.level_max !== '' ? filters.level_max : undefined;
  }

  if (filters && filters.types) {
    request_body.types = filters.types.length > 0 ? filters.types : undefined;
  }

  if (filters?.start_date && filters.end_date) {
    if (filters?.start_date === filters.end_date) {
      var midnight = moment().startOf('day').toString();
      var now = moment().toString();

      request_body[startDateType] = new Date(midnight).getTime();
      request_body[endDateType] = new Date(now).getTime();
    } else {
      request_body[startDateType] = new Date(filters.start_date).getTime();
      request_body[endDateType] = new Date(filters.end_date).getTime();
    }
  }

  return request_body;
};
