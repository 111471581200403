import React from 'react';
import Table from '../Table/Table';
// import './styles.css';
import {getStorage} from '../../utils/storage';
import {ENDPOINT} from '../../constants/api';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';
import {Modal} from '../Modal';
import {connect} from 'react-redux';
import {CSVLink} from 'react-csv';
import filtersData from '../../utils/filtersData';
import {ImageView} from '../ImageView';
import socket from '../../utils/socketIO';
import VehicleInfo from './VehicleInfo';
import UpdateInfo from './UpdateInfo';

let copyData = [];

class VehicleRecognition extends React.Component {
  state = {
    data: [],
    page: 1,
    limit: 10,
    selected_data: {},
    user: {},
    totalPageCount: 0,
    csvData: [],
    filepath: '',
    isReaTime: false,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);


    if (this.state.isReaTime) {
      socket.on('vehicles', () => {
        this.getData(false);
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const {filters, valid, invalid} = this.props;
    const {page, limit} = this.state;

    let request_body = filtersData({
      page,
      limit,
      filters,
    });

    if (valid) {
      request_body.valid_plate = true;
    }

    if (invalid) {
      request_body.valid_plate = false;
    }

    request_body.page = page;
    request_body.limit = limit;

    return request_body;
  }

  getData(isLoading, params) {
    const {user} = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnFilters();

    if (params?.search_text) {
      request_body.search = params.search_text;
    }

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_vehicle_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data, count} = res.data;

        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        copyData = data.slice(0);
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      },
    );
  }

  handleOpenModal(modal, selected_data) {
    if (modal === 'showImageModal') {
      this.setState({
        [modal]: true,
        filepath: selected_data.filepath,
        selected_data,
      });
      return;
    }

    this.setState({
      [modal]: true,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({search_text});

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({data: array});
  }

  handlePressSearch() {
    const {search_text} = this.state;

    if (search_text === '') return;

    this.getData(true, {
      search_text,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: 'Gate camera-id',
        key: 'vehicle_identity.camera_id',
      },
      {
        title: 'Date',
        key: 'createdAt',
        formatTime: 'DD-MM-YYYY HH:mm:ss',
        isMoment: true,
      },
      {
        title: 'Time',
        key: 'createdAt',
        isMoment: true,
        type: 'timeAgo',
      },
      {
        title: 'Plate',
        key: 'vehicle_identity.plate',
        type: 'link',
        onPress: item => this.handleOpenModal('showVehicleInfoModal', item),
      },
      {
        title: 'Direction',
        key: 'vehicle_identity.direction',
      },
      {
        title: 'AI Autocorrect',
        key: 'vehicle_identity.corrected_direction',
      },
      // {
      //   title: 'Plate Color',
      //   key: 'vehicle_identity.plate_color',
      // },
      {
        title: 'Type',
        key: 'vehicle_identity.vehicle_type',
      },
      {
        title: 'Color',
        key: 'vehicle_identity.vehicle_color',
      },
      // {
      //   title: 'Vehicle Brand',
      //   key: 'vehicle_identity.vehicle_brand',
      // },
      {
        title: 'Plate Image',
        key: 'vehicle_identity.plate_image',
        type: 'photo',
        onPress: item =>
          this.handleOpenModal('showImageModal', {
            filepath: item.vehicle_identity.plate_image,
            plate: item.vehicle_identity.plate,
          }),
      },
      {
        title: 'Full Image',
        key: 'vehicle_identity.full_image',
        type: 'photo',
        onPress: item =>
          this.handleOpenModal('showImageModal', {
            filepath: item.vehicle_identity.full_image,
            plate: item.vehicle_identity.plate,
          }),
      },
    ];

    return headers;
  }

  handleRealTime(e) {
    this.setState({
      isReaTime: e.target.checked,
    });

    if (e.target.checked) {
      socket.connect();
    } else {
      socket.disconnect();
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <Table
            data={this.state.data}
            no_bordered
            isSearch
            style={{marginBottom: 0}}
            totalPageCount={this.state.totalPageCount}
            page={this.state.page}
            search_text={this.state.search_text}
            handleSearch={this.handleSearch.bind(this)}
            addSearchButton
            handlePressSearch={this.handlePressSearch.bind(this)}
            limit={this.state.limit}
            isLoading={this.state.isLoading}
            handlePagination={this.handlePagination.bind(this)}
            headers={this.returnTableHeaders()}
            rowPress={item =>
              this.handleOpenModal('showVehicleUpdateModal', item)
            }
            showRealTime
            isReaTime={this.state.isReaTime}
            checkboxLabel="Real Time Update"
            handleRealTime={this.handleRealTime.bind(this)}
          />
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, 'showImageModal')}
          show={this.state.showImageModal}
          title={this.state?.selected_data?.plate}
          size="lg">
          <ImageView
            filepath={this.state?.filepath}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              'showImageModal',
            )}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, 'showVehicleInfoModal')}
          show={this.state.showVehicleInfoModal}
          size="lg"
          title={this.state?.selected_data?.vehicle_identity?.plate || ''}>
          <VehicleInfo
            selected_data={this.state.selected_data}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              'showVehicleInfoModal',
            )}
            dates={{
              start_date: this.props.filters.start_date,
              end_date: this.props.filters.end_date,
            }}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            'showVehicleUpdateModal',
          )}
          show={this.state.showVehicleUpdateModal}
          size="lg"
          title={this.state?.selected_data?.vehicle_identity?.plate || ''}>
          <div className="card">
            <div className="card-body">
              <UpdateInfo
                selected_data={this.state.selected_data}
                handleCloseModal={this.handleCloseModal.bind(
                  this,
                  'showVehicleInfoModal',
                )}
              />
            </div>
          </div>
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={'Water Tank' + new Date().getTime()}
          data={this.state.csvData}></CSVLink>
      </div>
    );
  }
}

const mapStateProps = state => {
  const {filters} = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateProps)(VehicleRecognition);
