import React from 'react';
import toastMessage from '../../utils/toastMessage';
import axios from 'axios';
import {Button} from '../Button';
import {ENDPOINT} from '../../constants/api';
import {Input} from '../Input';
import {getStorage} from '../../utils/storage';

class UpdateInfo extends React.Component {
  state = {
    plate: '',
    direction: '',
    vehicle_color: '',
    vehicle_type: '',
    _id: '',
    error: {},
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    const {selected_data} = this.props
    console.log({selected_data})
    const {vehicle_identity} = selected_data
    const {plate, vehicle_color, vehicle_type, direction} = vehicle_identity;

    this.setState({plate, vehicle_color, vehicle_type, direction});
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  onChangeText(name, e) {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    this.setState({
      error,
      [name]: value,
    });
  }

  validateForm() {
    let {error, plate} = this.state;

    if (plate === '') {
      error.plate = 'Plate is required';
    }

    this.setState({error});
  }

  onSubmit = async () => {
    await this.validateForm();

    const {error, user, direction, vehicle_type, vehicle_color} = this.state;

    const {selected_data} = this.props;
    const {_id} = selected_data

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let url = ENDPOINT + '/update_check_in_out_info';

      let {vehicle_identity} = selected_data;

      vehicle_identity['direction'] = direction;
      vehicle_identity['vehicle_type'] = vehicle_type;
      vehicle_identity['vehicle_color'] = vehicle_color;

      let data = {
        vehicle_identity,
        id: _id,
      };

      delete data._id;

      const options = {
        method: 'POST',
        url,
        data,
        headers: {
          authorization: 'Bearer ' + user.token,
        },
      };

      axios(options)
        .then(data => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage('success', `Info updated successfully`);
        })
        .catch(error => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage('error', error);
        });
    }
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <Input
              label="Plate:"
              required
              className="form-control-lg"
              value={this.state.plate}
              onChange={e => this.onChangeText('plate', e)}
              error={this.state.error.plate}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Vehicle Type:"
              className="form-control-lg "
              value={this.state.vehicle_type}
              onChange={e => this.onChangeText('vehicle_type', e)}
              error={this.state.error.vehicle_type}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Vehicle Color:"
              className="form-control-lg "
              value={this.state.vehicle_color}
              onChange={e => this.onChangeText('vehicle_color', e)}
              error={this.state.error.vehicle_color}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Direction:"
              className="form-control-lg "
              value={this.state.direction}
              onChange={e => this.onChangeText('direction', e)}
              error={this.state.error.direction}
            />
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}} />
          <Button
            className="btn-primary"
            text="Update"
            isSubmitting={this.state.isSubmitting}
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default UpdateInfo;
