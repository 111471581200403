import React from 'react';
import {Button} from '../Button';
import {Input, Select} from '../Input';

import './styles.css';
import {ENDPOINT} from '../../constants/api';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';
import {getStorage} from '../../utils/storage';
import {
  cells,
  districts,
  provinces,
  sectors,
  villages,
} from '../../utils/rwanda';
import {availables, thing_status, thing_types} from '../../constants/strings';
import formatSelectData from '../../utils/formatSelectData';

class NewThing extends React.Component {
  state = {
    email: '',
    password: '',
    error: {},
    isSubmitting: false,
    user: {},
    _id: '',
    provinces: [],
    districts: [],
    sectors: [],
    cells: [],
    villages: [],
    address: '',
    devices: [],
    status: thing_status[0],
    types: thing_types[0],
    available: availables[0],
    search_device: '',
    latitude: '',
    longitude: '',
    area: '',
    height: '',
    thing_id: '',
    first_alert_at: '',
    final_alert_at: '',
    access_groups: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getDevices(true);
    this.getGroups(true);

    this.setState({
      provinces: provinces(),
    });

    if (this.props._id && this.props._id !== '') {
      this.setState({
        ...this.props,
        address: this.props.address.geolocation,
        latitude: this.props.address.lat,
        longitude: this.props.address.long,
        device: {
          label: this.props.device_id.device_id,
          value: this.props.device_id._id,
        },
        province: {
          label: this.props.address.province,
          value: this.props.address.province,
        },
        districts: districts(this.props.address.province),
        sectors: sectors(
          this.props.address.province,
          this.props.address.district,
        ),
        cells: cells(
          this.props.address.province,
          this.props.address.district,
          this.props.address.sector,
        ),
        villages: villages(
          this.props.address.province,
          this.props.address.district,
          this.props.address.sector,
          this.props.address.cell,
        ),
        district: {
          label: this.props.address.district,
          value: this.props.address.district,
        },
        sector: {
          label: this.props.address.sector,
          value: this.props.address.sector,
        },
        cell: {
          label: this.props.address.cell,
          value: this.props.address.cell,
        },
        village: {
          label: this.props.address.village,
          value: this.props.address.village,
        },
        status: {
          label: this.props.status,
          value: this.props.status,
        },
        type: {
          label: this.props.type,
          value: this.props.type,
        },
        available: {
          label: this.props.available + '',
          value: this.props.available + '',
        },
        access_group: this.populateSelect(
          this.state.access_groups,
          'group_id',
          this.props.access_group,
          true,
        ),
      });
    }
  };

  populateSelect(data, label, value, resultIsArray) {
    let current_item = resultIsArray ? [] : {};

    for (let i = 0; i < data.length; i++) {
      if (resultIsArray && value.includes(data[i].value)) {
        current_item.push({
          label: data[i][label],
          value: data[i].value,
        });
      } else if (data[i].value === value) {
        current_item = data[i];
      }
    }

    return current_item;
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getGroups(isLoadingGroup) {
    const {user} = this.state;

    this.setState({
      isLoadingGroup,
    });

    let url = ENDPOINT + '/get_access_group';

    let data = {
      page: 1,
      limit: 10,
    };

    const options = {
      method: 'POST',
      url,
      data,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        let {data} = res.data;

        let access_groups = formatSelectData(data, 'group_id', '_id');
        this.setState({
          access_groups,
          isLoadingGroup: false,
        });
      })
      .catch(error => {
        this.setState({
          isLoadingGroup: false,
        });

        toastMessage('error', error);
      });
  }

  getDevices(isFetchingDevices, search_device) {
    this.setState({
      isFetchingDevices,
    });

    const {user} = this.state;

    let body = {
      page: 1,
      limit: 20,
    };

    if (search_device && search_device !== '') {
      body.search = search_device;
    }

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_device_info',
      data: {
        ...body,
      },
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    return axios(options).then(res => {
      const devices = formatSelectData(res.data.data, 'device_id', '_id');

      this.setState({
        devices,
        isFetchingDevices: false,
      });

      return devices;
    });
  }

  onChangeText = async (name, e) => {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    await this.setState({
      error,
      [name]: value,
    });

    if (name === 'province') {
      this.setState({
        districts: districts(value.value),
        sectors: [],
        sector: undefined,
        cells: [],
        cell: undefined,
        villages: [],
        village: undefined,
        district: undefined,
      });
    }

    if (name === 'district') {
      this.setState({
        sectors: sectors(this.state.province.value, value.value),
        sector: undefined,
        cells: [],
        cell: undefined,
        villages: [],
        village: undefined,
      });
    }

    if (name === 'sector') {
      this.setState({
        cells: cells(
          this.state.province.value,
          this.state.district.value,
          value.value,
        ),
        cell: undefined,
        villages: [],
        village: undefined,
      });
    }

    if (name === 'cell') {
      this.setState({
        villages: villages(
          this.state.province.value,
          this.state.district.value,
          this.state.sector.value,
          value.value,
        ),
        village: undefined,
      });
    }
  };

  validateForm() {
    let {
      thing_id,
      device,
      address,
      province,
      district,
      sector,
      cell,
      village,
      error,
      type,
      status,
      longitude,
      latitude,
      area,
      height,
      first_alert_at,
      final_alert_at,
    } = this.state;

    if (thing_id === '') {
      error.thing_id = 'Object ID is required';
    }

    if (!device) {
      error.device = 'Please select device';
    }

    if (address === '' || latitude === '' || longitude === '') {
      error.address = 'Location is required';
    }

    if (latitude === '') {
      error.latitude = 'Latitude is required';
    }

    if (longitude === '') {
      error.longitude = 'Longitude is required';
    }

    if (!province) {
      error.province = 'Province is required';
    }

    if (!type) {
      error.type = 'Thing type is required';
    }

    if (!status) {
      error.status = 'Thing status is required';
    }

    if (!district) {
      error.district = 'District is required';
    }

    if (!sector) {
      error.sector = 'Sector is required';
    }

    if (!cell) {
      error.cell = 'Cell is required';
    }

    if (!village) {
      error.village = 'Village is required';
    }

    if (area === '') {
      error.area = 'Thing area is required';
    }

    if (height === '') {
      error.height = 'Thing height is required';
    }

    if (first_alert_at === '') {
      error.first_alert_at = 'Alert at (min) is required';
    }

    if (final_alert_at === '') {
      error.final_alert_at = 'Alert at (max) is required';
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      thing_id,
      device,
      address,
      province,
      district,
      sector,
      cell,
      village,
      user,
      _id,
      error,
      status,
      available,
      type,
      latitude,
      longitude,
      area,
      height,
      access_group,
      collector,
      final_alert_at,
      first_alert_at,
    } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let _collector, _access_group;

      if (collector && collector.length > 0) {
        _collector = [];
        for (let i = 0; i < collector.length; i++) {
          _collector.push(collector[i].value);
        }
      }

      if (access_group && access_group.length > 0) {
        _access_group = [];
        for (let i = 0; i < access_group.length; i++) {
          _access_group.push(access_group[i].value);
        }
      }

      let url = ENDPOINT + '/add_thing_info';

      let data = {
        thing_id,
        area,
        height,
        address: {
          lat: latitude,
          long: longitude,
          province: province.value,
          district: district.value,
          sector: sector.value,
          cell: cell.value,
          village: village.value,
          geolocation: address,
        },
        device_id: device.value,
        status: status.value,
        available: available.value,
        type: type.value,
        first_alert_at,
        final_alert_at,
        collector: _collector,
        access_group: _access_group,
      };

      if (user.ref_account) {
        data.ref_account = user.ref_account;
      }

      if (user.ref_subaccount) {
        data.ref_subaccount = user.ref_subaccount;
      }

      if (_id !== '') {
        url = ENDPOINT + '/update_thing_info';
        data.id = _id;
      }

      const options = {
        method: 'POST',
        url,
        data,
        headers: {
          authorization: 'Bearer ' + user.token,
        },
      };

      axios(options)
        .then(data => {
          this.setState({
            isSubmitting: false,
            email: '',
            password: '',
            role: {},
            contact: '',
          });

          toastMessage(
            'success',
            `Account was ${
              this.state._id !== '' ? 'updated' : 'created'
            } successfully`,
          );

          this.props.handleCloseModal();
          this.props.getThing(true);
        })
        .catch(error => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage('error', error);
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-">
              <Input
                label="Object ID:"
                required
                className="form-control-lg"
                value={this.state.thing_id}
                onChange={e => this.onChangeText('thing_id', e)}
                error={this.state.error.thing_id}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Thing Area:"
                required
                className="form-control-lg"
                value={this.state.area}
                onChange={e => this.onChangeText('area', e)}
                error={this.state.error.area}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Thing Height:"
                required
                className="form-control-lg"
                value={this.state.height}
                onChange={e => this.onChangeText('height', e)}
                error={this.state.error.height}
              />
            </div>
            <div className="col-md-12">
              <Select
                defaultOptions={this.state.devices}
                label="Device:"
                className="form-control-lg "
                value={this.state.device}
                onChange={e => this.onChangeText('device', e)}
                error={this.state.error.device}
                required
                async
                isLoading={this.state.isFetchingDevices}
                onInputChange={e => this.onChangeText('search_device', e)}
                loadOptions={inputValue => this.getDevices(true, inputValue)}
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Address:"
                required
                className="form-control-lg"
                value={this.state.address}
                onChange={e => this.onChangeText('address', e)}
                error={this.state.error.address}
              />
            </div>
            <div className="col-md-12">
              <Select
                defaultOptions={this.state.access_groups}
                label="Access Group:"
                className="form-control-lg "
                value={this.state.access_group}
                onChange={e => this.onChangeText('access_group', e)}
                error={this.state.error.access_group}
                async
                isMulti
                isLoading={this.state.isLoadingGroup}
                onInputChange={e => this.onChangeText('search_group', e)}
                loadOptions={inputValue => this.getGroups(true, inputValue)}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Latitude:"
                required
                className="form-control-lg"
                value={this.state.latitude}
                onChange={e => this.onChangeText('latitude', e)}
                error={this.state.error.latitude}
                type="number"
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Longitude:"
                required
                className="form-control-lg"
                value={this.state.longitude}
                onChange={e => this.onChangeText('longitude', e)}
                error={this.state.error.longitude}
                type="number"
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.provinces}
                label="Province"
                className="form-control-lg "
                value={this.state.province}
                onChange={e => this.onChangeText('province', e)}
                error={this.state.error.province}
                required
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.districts}
                label="District"
                className="form-control-lg "
                value={this.state.district}
                isDisabled={!this.state.province}
                onChange={e => this.onChangeText('district', e)}
                error={this.state.error.district}
                required
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.sectors}
                label="Sector"
                className="form-control-lg "
                value={this.state.sector}
                onChange={e => this.onChangeText('sector', e)}
                error={this.state.error.sector}
                required
                isDisabled={!this.state.district}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.cells}
                label="Cell"
                className="form-control-lg "
                value={this.state.cell}
                onChange={e => this.onChangeText('cell', e)}
                error={this.state.error.cell}
                isDisabled={!this.state.sector}
                required
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.villages}
                label="Village"
                className="form-control-lg "
                value={this.state.village}
                onChange={e => this.onChangeText('village', e)}
                error={this.state.error.village}
                isDisabled={!this.state.cell}
                required
              />
            </div>
            <div className="col-md-6">
              <Select
                options={thing_types}
                label="Thing Type:"
                className="form-control-lg "
                value={this.state.type}
                onChange={e => this.onChangeText('type', e)}
                error={this.state.error.type}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Alert at (min):"
                required
                className="form-control-lg"
                value={this.state.first_alert_at}
                onChange={e => this.onChangeText('first_alert_at', e)}
                error={this.state.error.first_alert_at}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Alert at (max):"
                required
                className="form-control-lg"
                value={this.state.final_alert_at}
                onChange={e => this.onChangeText('final_alert_at', e)}
                error={this.state.error.final_alert_at}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={thing_status}
                label="Status:"
                className="form-control-lg "
                value={this.state.status}
                onChange={e => this.onChangeText('status', e)}
                error={this.state.error.status}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={availables}
                label="Available:"
                className="form-control-lg "
                value={this.state.available}
                onChange={e => this.onChangeText('available', e)}
                error={this.state.error.available}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default NewThing;
