import React from 'react';
import './styles.css';
import {Title} from '../Title';
import waterLevelImg from '../../../assets/vehicle-recognition.png';
import Fade from 'react-reveal/Fade';
// import cameraImg from '../../../assets/Camera scanner.png';

const VehicleRecognition = () => {
  return (
    <div
      className="vehicle-recognition-container"
      style={{position: 'relative'}}>
      {/* <img src={cameraImg} className="cameraImage" /> */}
      <div className="container">
        <div className="row">
          <div className="col-xs-12  col-sm-12 col-md-6">
            <Title text="Vehicle Recognition" smText="Features" />
          </div>
          <div className="col-xs-12  col-sm-12 col-md-6">
            <Fade bottom>
              <p className="description">
                The feature provides real-time monitoring and seamless check-ins
                and checkouts in Kigali Special Economic Zones. This
                cutting-edge feature streamlines vehicle management and enhances
                security, enabling efficient monitoring of facility access and
                track vehicle usage.
              </p>
            </Fade>
          </div>
        </div>
        <Fade bottom>
          <div className="row">
            <div className="col-md-12 vehicle-recognition-img-container">
              <img src={waterLevelImg} />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default VehicleRecognition;
