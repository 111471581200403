import React from 'react';
import './styles.css';
import {Title} from '../Title';
import waterLevelImg from '../../../assets/water-levels.png';
import Fade from 'react-reveal/Fade';

const WaterLevel = () => {
  return (
    <div className="water-level-container">
      <div className="container">
        <div className="row">
          <div className="col-md-6 water-header">
            <Title text="Water Level Monitoring" smText="Features" />
          </div>
          <div className="col-md-6 ">
            <Fade bottom>
              <p className="description">
                Experience the power of advanced water monitoring system for
                Kigali Special Economic Zones. With real-time data on water
                levels, the platform enables you to optimize water usage and
                reduce wastage. The system also provides alerts for potential
                water-related tank level issues, allowing you to take proactive
                measures to mitigate risks and ensure business continuity.
              </p>
            </Fade>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 water-level-img-container">
            <Fade bottom>
              <img src={waterLevelImg} />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterLevel;
