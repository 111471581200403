import React from 'react';
import {Analytics} from '../components/AirQualityAnalytics';
import {Home} from './common';

class AirQualityAnalyticsScreen extends React.Component {
  renderLevels() {
    return <Analytics />;
  }

  render() {
    return (
      <div>
        <Home date location />
        <div>{this.renderLevels()}</div>
      </div>
    );
  }
}

export default AirQualityAnalyticsScreen;
