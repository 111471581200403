import React from 'react';
import Table from '../Table/Table';
import './styles.css';
import axios from 'axios';
import {connect} from 'react-redux';
import {ENDPOINT} from '../../constants/api';
import {getStorage} from '../../utils/storage';
import toastMessage from '../../utils/toastMessage';
import {Modal} from '../Modal';
import './styles.css';
import {DeviceInfo} from '../Devices';

let copyData = [];
class SensorActivities extends React.Component {
  state = {
    data: [],
    limit: 10,
    page: 1,
    isLoading: true,
    selected_thing: {},
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const {user, page, limit} = this.state;

    this.setState({
      isLoading,
    });

    let request_body = {
      target: 'last',
      page,
      limit,
    };

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_current_device_status_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data, count} = res.data;
        let result = [];

        for (let i = 0; i < data.length; i++) {
          result.push({
            ...data[i].last,
            device_status: data[i].last.device_status.toLowerCase(),
            device: data[i].last.thing ? data[i].last.thing[0] : {},
            geolocation: data[i].last.thing
              ? data[i]?.last?.thing[0]?.address?.geolocation
              : '',
          });
        }

        this.setState({
          data: result,
          isLoading: false,
          totalPageCount: count,
        });

        copyData = result.slice(0);
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      },
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({search_text});

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({data: array});
  }

  handleOpenModal(modal, selected_thing) {
    this.setState({
      [modal]: true,
      selected_thing: selected_thing.device ? selected_thing.device : {},
      modalTitle: selected_thing.device.device_id
        ? 'Thing #' + selected_thing.device.device_id
        : '',
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    console.log('================skjskjskjdskjksjsd====================');
    console.log(this.state.data);
    console.log('====================================');
    return (
      <div className="card">
        <div className="card-header">
          <h3>Sensors Offline Activities</h3>
        </div>
        <div className="card-body">
          <Table
            data={this.state.data}
            no_bordered
            isLoading={this.state.isLoading}
            style={{marginBottom: 0}}
            headers={[
              {
                title: 'Status',
                key: 'device_status',
                type: 'sensor_status',
              },
              {
                title: 'Timestamp',
                key: 'createdAt',
                isMoment: true,
                formatTime: 'lll',
              },
              {
                title: 'Last seen',
                key: 'updatedAt',
                isMoment: true,
                formatTime: 'lll',
              },
              {
                title: 'Object ID',
                key: 'device.thing_id',
                type: 'link',
                onPress: this.handleOpenModal.bind(this, 'showModal'),
              },
              {
                title: 'Type of Thing',
                key: 'device.type',
              },
              {
                title: 'Location',
                key: 'geolocation',
              },
            ]}
            actions={[
              {
                name: 'Report',
                onPress: null,
              },
            ]}
          />
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, 'showModal')}
          show={this.state.showModal}
          title={this.state.modalTitle}
          size="lg">
          <DeviceInfo
            {...this.state.selected_thing}
            handleCloseModal={this.handleCloseModal.bind(this, 'showModal')}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {filters} = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(SensorActivities);
