import React from 'react';
import './styles.css';
import RDB from '../../../assets/rdb.png';
import MINICT from '../../../assets/minict.png';
import GIZ from '../../../assets/GIZ.png';
import KSEZ from '../../../assets/KSEZ.png';
import DIGICENTER from '../../../assets/digicenter.png';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const options = [
  {
    perPage: 3,
    arrows: false,
    pagination: false,
  },
];

class Partner extends React.Component {
  state = {
    perPage: 4,
  };

  handleResize = () => {
    console.log({width: window.innerWidth});
    if (window.innerWidth < 768) {
      this.setState({perPage: 1});
    } else if (window.innerWidth < 992) {
      this.setState({perPage: 2});
    } else {
      this.setState({perPage: 3});
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
    return () => {
      window.removeEventListener('resize', this.handleResize.bind(this));
    };
  }
  render() {
    const logo = [
      {
        name: 'MINICT',
        logo: MINICT,
      },
      {
        name: 'GIZ',
        logo: GIZ,
      },
      {
        name: 'RDB',
        logo: RDB,
      },
      {
        name: 'Digital Center',
        logo: DIGICENTER,
      },
      {
        name: 'KSEZ',
        logo: KSEZ,
      },
    ];

    console.log(this.state.perPage);
    return (
      <div className="partner-container">
        <div className="container">
          <div className="logo-container">
            <Splide
              options={{
                arrows: true,
                pagination: false,
                autoplay: true,
                perPage: this.state.perPage,
                type: 'loop',
              }}>
              {logo.map((el, i) => {
                return (
                  <SplideSlide key={i}>
                    <div className="logo-item">
                      <img src={el.logo} alt={el.name} />
                    </div>
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        </div>
      </div>
    );
  }
}

export default Partner;
