import React from 'react';
import {Login} from '../components/Login';
import {NavBar} from '../components/LandingPage/NavBar';

class LoginScreen extends React.Component {
  render() {
    return (
      <div>
        {/* <NavBar /> */}
        <Login />
      </div>
    );
  }
}

export default LoginScreen;
