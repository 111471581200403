import React from 'react';
import './styles.css';
import {Button} from '../Button';
import {WaterType, Location, WaterLevel, DateRange} from '.';
import UserAccounts from './UserAccounts';
import Thing from './Thing';

const Header = props => {
  const {filters} = props;
  if (!filters) {
    return <></>;
  }
  return (
    <div className="main-filters d-grid  d-md-flex align-items-center">
      <div className={`row main-filters`}>
        {filters.map((item, i) => {
          return (
            <div
              key={i}
              className={`${item.isDropdown && 'dropdown'}  col-auto`}>
              <Button
                className={`btn-default bordered btn-sm ${
                  item.isDropdown && ' dropdown-toggle dropdown-toggle-split'
                }`}
                text={item.name}
                selected={
                  item.selected ? item.selected : item.isDropdown ? ['All'] : ''
                }
                autoCloseType={item.autoCloseType}
                id={item.clickBehaviorId}
                withOpacity
              />
              {item.isDropdown && item.name === 'locations' && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside">
                  <Location {...props} />
                </ul>
              )}
              {item.isDropdown && item.name === 'user_account' && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside">
                  <UserAccounts />
                </ul>
              )}
              {item.isDropdown && item.name === 'type' && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside">
                  <WaterType />
                </ul>
              )}
              {item.isDropdown && item.name === 'trash level' && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside">
                  <WaterLevel />
                </ul>
              )}
              {item.isDropdown && item.name === 'thing' && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside">
                  <Thing />
                </ul>
              )}
              {item.isDropdown && item.name === 'date' && (
                <ul
                  className="dropdown-menu col-xs-12"
                  aria-labelledby="dropdownMenuClickableInside">
                  <DateRange />
                </ul>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Header;
