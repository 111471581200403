import axios from 'axios';
import React from 'react';
import {connect} from 'react-redux';
import {ENDPOINT} from '../../constants/api';
import {getStorage} from '../../utils/storage';
import toastMessage from '../../utils/toastMessage';
import {ThingInfo} from '../Thing';
import {Modal} from '../Modal';
import {CSVLink} from 'react-csv';
import Table from '../Table/Table';
import './styles.css';
import exportPDF from '../../utils/exportPDF';

let copyData = [];
class WaterCollections extends React.Component {
  state = {
    data: [],
    limit: 10,
    page: 1,
    isLoading: true,
    selected_thing: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const {filters} = this.props;
    let request_body = {};

    if (filters && filters.locations && filters.locations.length > 0) {
      request_body.sectors = filters.locations;
    }

    if (
      filters &&
      (filters.level_min || filters.level_min === 0) &&
      filters.level_max
    ) {
      request_body.start_level_percentage =
        filters.level_min !== '' ? filters.level_min : undefined;

      request_body.end_level_percentage =
        filters.level_max !== '' ? filters.level_max : undefined;
    }

    if (filters && filters.just_collected) {
      request_body.just_collected = true;
    }

    if (filters && filters.types) {
      request_body.types = filters.types.length > 0 ? filters.types : undefined;
    }

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }

    return request_body;
  }

  getData(isLoading) {
    const {user, page, limit} = this.state;

    this.setState({
      isLoading,
    });

    let request_body = this.returnFilters();

    request_body.status = 'served';
    request_body.page = page;
    request_body.limit = limit;

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_schedule_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data, count} = res.data;

        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        copyData = data.slice(0);
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      },
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({search_text});

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({data: array});
  }

  handleOpenModal(modal, selected_thing) {
    this.setState({
      [modal]: true,
      selected_thing: selected_thing.thing_id ? selected_thing.thing_id : {},
      modalTitle: selected_thing.thing_id
        ? 'Thing #' + selected_thing.thing_id.thing_id
        : '',
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  downloadExcel() {
    const {user} = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = this.returnFilters();

    request_body.status = 'served';

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_schedule_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const data = res.data;

        let result = [];

        for (let i = 0; i < data.length; i++) {
          delete data[i].info;
          delete data[i]._v;

          if (data[i].address) {
            data[i].address = data[i].address.geolocation;
          }

          if (data[i].thing_id) {
            data[i].thing_id = data[i].thing_id.thing_id;
          }

          if (data[i].device_id) {
            data[i].device_id = data[i].device_id.imei;
          }

          result.push({
            ...data[i],
          });
        }

        this.setState(
          {
            csvData: result,
            isLoading: false,
          },
          () => {
            this.refs.csvDownload?.link.click();
          },
        );
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  downloadPDF() {
    const headers = this.returnTableHeaders();

    const {user} = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = this.returnFilters();

    request_body.status = 'served';

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_schedule_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data, count} = res.data;

        this.setState({
          isLoading: false,
        });

        exportPDF('Water Levels', headers, data);
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  render() {
    let headers = [];
    return (
      <div className="card">
        <div className="card-body">
          <Table
            data={this.state.data}
            no_bordered
            isSearch
            style={{marginBottom: 0}}
            totalPageCount={this.state.totalPageCount}
            page={this.state.page}
            search_text={this.state.search_text}
            handleSearch={this.handleSearch.bind(this)}
            limit={this.state.limit}
            isLoading={this.state.isLoading}
            handlePagination={this.handlePagination.bind(this)}
            headers={[
              {
                title: 'Alert Levels',
                key: 'level',
                type: 'level',
              },
              {
                title: 'Previous Collection',
                key: 'served_date',
                isMoment: true,
                formatTime: 'lll',
              },
              {
                title: 'Location',
                key: 'address.geolocation',
              },
              {
                title: 'Schedule Time',
                key: 'schedule_date',
                isMoment: true,
                formatTime: 'lll',
              },
              {
                title: 'Status',
                key: 'status',
              },

              {
                title: 'Object ID',
                key: 'thing_id.thing_id',
                type: 'link',
                onPress: this.handleOpenModal.bind(this, 'showModal'),
              },
              {
                title: 'Type',
                key: 'thing_id.type',
              },
              {
                title: 'Served Time',
                key: 'served_date',
                isMoment: true,
                formatTime: 'lll',
              },
              {
                title: 'Served Level',
                key: 'served_detection_level',
                type: 'level',
              },
            ]}
            filters={[
              {
                type: 'export',
                title: 'Export',
                button_type: 'dropdown',
                icon: 'bx bxs-download',
                options: [
                  {
                    name: 'PDF',
                    onPress: this.downloadPDF.bind(this),
                  },
                  {
                    name: 'CSV',
                    onPress: this.downloadExcel.bind(this),
                  },
                ],
              },
            ]}
          />
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, 'showModal')}
          show={this.state.showModal}
          title={this.state.modalTitle}
          size="lg">
          <ThingInfo
            {...this.state.selected_thing}
            handleCloseModal={this.handleCloseModal.bind(this, 'showModal')}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={'WaterCollection' + new Date().getTime()}
          data={this.state.csvData}></CSVLink>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {filters} = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(WaterCollections);
