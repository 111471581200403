import React from 'react';
import './styles.css';

const FloatButton = props => {
  return (
    <div className="goUpDownContainer">
      <a href="#home">
        <i class="bx bx-chevron-up"></i>
      </a>
    </div>
  );
};

export default FloatButton;
