import React from 'react';
import './styles.css';
import {Bar} from 'react-chartjs-2';
import {getStorage} from '../../utils/storage';
import {ENDPOINT} from '../../constants/api';
import axios from 'axios';
import toastMessage from '../../utils/toastMessage';
import {connect} from 'react-redux';
import {LoadingSpinner} from '../LoadingSpinner';

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
  // scales: {
  //   xAxes: [
  //     {
  //       barPercentage: 0.2,
  //     },
  //   ],
  // },
};

class WaterLevelChart extends React.Component {
  state = {
    data: [],
    user: {},
    selected_alert: {},
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getData(isLoading) {
    const {user} = this.state;
    const {filters} = this.props;

    this.setState({
      isLoading,
    });

    let request_body = {
      tag: 'trend',
      order: 'daily',
    };

    if (filters && filters.locations && filters.locations.length > 0) {
      request_body.sectors = filters.locations;
    }

    if (
      filters &&
      (filters.level_min || filters.level_min === 0) &&
      filters.level_max
    ) {
      request_body.start_level_percentage =
        filters.level_min !== '' ? filters.level_min : undefined;

      request_body.end_level_percentage =
        filters.level_max !== '' ? filters.level_max : undefined;
    }

    if (filters && filters.just_collected) {
      request_body.just_collected = true;
    }

    if (filters && filters.types) {
      request_body.types = filters.types.length > 0 ? filters.types : undefined;
    }

    if (filters && filters.start_date && filters && filters.end_date) {
      request_body.start_date = new Date(filters.start_date).getTime();
      request_body.end_date = new Date(filters.end_date).getTime();
    }

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_Waterlevel_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const data = res.data;

        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          labels.push(data[i]._id);
          chart_data.push(Math.round(data[i].total_level * 100) / 100);
        }

        this.setState({
          isLoading: false,
          data: {
            labels,
            datasets: [
              {
                label: 'Levels per day (L)',
                data: chart_data,
                borderRadius: 15,
                backgroundColor: '#05c605',
                borderColor: 'rgba(0,0,0,1)',
                barPercentage: 0.2,
              },
            ],
          },
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoading: false});
      });
  }

  render() {
    return (
      <div className="chart-container">
        <div className="card">
          <div className="card-header">
            <h3>Daily Max Water Levels</h3>
          </div>
          <div className="card-body" style={{height: 280}}>
            {this.state.isLoading ? (
              <LoadingSpinner />
            ) : (
              <Bar data={this.state.data} options={options} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {filters} = state.Filters;
  return {filters};
};

export default connect(mapStateToProps)(WaterLevelChart);
