import axios from 'axios';
import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {CardCount} from '../../components/CardCount';
import {ENDPOINT} from '../../constants/api';
import {getStorage} from '../../utils/storage';
import toastMessage from '../../utils/toastMessage';
import {LoadingSpinner} from '../LoadingSpinner';
import {Line, Doughnut} from 'react-chartjs-2';
import {colors, days} from '../../constants/strings';
import filtersData from '../../utils/filtersData';
import {numberWithCommas} from '../../utils/formatnNumber';
import WaterUsedPerTank from './WaterUsedPerTank';
import returnChartList from './utils/returnChartList';

const options = {
  legend: {
    position: 'bottom',
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
};

class Analytics extends React.Component {
  state = {
    user: {},
    total_active_Thing: 0,
    total_collections: 0,
    total_number_tanks: 0,
    total_Water_used: 0,
    route: '',
    total_Water: 0,
    chart: {
      water_available_per_tank: [],
      fill_level: [],
      average: [],
      weekly_trends: [],
      daily_Water_level: [],
      water_used: [],
      hourly_Water_level: [],
    },
    chart_collections: [],
    top_five_available_per_tank: [],
    top_five_fill_level: [],
    top_five_water_used: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({user});

    this.getTotalWater(true);
    this.getHourlyWaterLevel(true);
    this.getDailyWaterLevel(true);
    this.getTotalWaterUsed(true);
    this.getWeeklyTrends(true);
    this.getDailyLevelThingType(true);
    this.getTopFiveWaterAvailablePerTank(true);
    this.getTopFiveFillLevel(true);
    this.getTopFiveWaterUsed(true);
  };

  componentDidUpdate = async prevProps => {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getTotalWater(true);
      this.getHourlyWaterLevel(true);
      this.getDailyWaterLevel(true);
      this.getTotalWaterUsed(true);
      this.getWeeklyTrends(true);
      this.getDailyLevelThingType(true);
      this.getTopFiveWaterAvailablePerTank(true);
      this.getTopFiveFillLevel(true);
      this.getTopFiveWaterUsed(true);
    }
  };

  returnFilters() {
    const {filters} = this.props;

    let request_body = filtersData({filters});

    request_body.target = 'all';

    return request_body;
  }

  getTotalWater(isLoadingWater) {
    const {user} = this.state;

    let request_body = this.returnFilters();

    delete request_body.target;
    request_body.tag = 'last';

    this.setState({
      isLoadingWater,
    });

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_level_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const data = res.data;

        let total = 0;

        for (let el of data) {
          total += Math.round(Number(el.last.level));
        }

        this.setState({
          isLoadingWater: false,
          total_Water: total,
          total_number_tanks: data.length,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoadingWater: false});
      });
  }

  getTotalWaterUsed(isLoadingWaterUsed) {
    const {user} = this.state;

    let request_body = this.returnFilters();

    this.setState({
      isLoadingWaterUsed,
    });

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_used_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data} = res.data;

        this.setState({
          isLoadingWaterUsed: false,
          total_Water_used: data,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoadingWaterUsed: false});
      });
  }

  getTopFiveWaterAvailablePerTank(isLoadingAvailablePerTank) {
    const {user} = this.state;

    let request_body = this.returnFilters();

    request_body.page = 1;
    request_body.limit = 5;
    request_body.tag = 'rank';

    this.setState({
      isLoadingAvailablePerTank,
    });

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_level_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data} = res.data;

        let chart_data = [],
          labels = [],
          {top_five_available_per_tank} = this.state;

        for (let i = 0; i < data.length; i++) {
          if (data[i].thing && data[i].thing.length > 0) {
            labels.push(
              `${data[i].thing[0].address.geolocation} (${data[
                i
              ].thing[0].type.toLowerCase()})`,
            );

            top_five_available_per_tank.push({
              label: data[i].thing[0].thing_id,
              value: Math.round(data[i].total_level),
            });

            let label = `${data[i].thing[0].address.geolocation} (${data[
              i
            ].thing[0].type.toLowerCase()})`;

            chart_data.push([label, Math.round(data[i].total_level)]);
          }
        }
        chart_data = chart_data.sort((a, b) => b[1] - a[1]);
        chart_data = chart_data.map(el => el[1]);

        let customLabels = labels.map(
          (label, index) =>
            `${label}: ${numberWithCommas(Math.round(chart_data[index]))}`,
        );

        this.state.chart.water_available_per_tank = {
          labels: customLabels,
          datasets: [
            {
              data: chart_data,
              backgroundColor: colors(1),
            },
          ],
        };

        this.setState({
          isLoadingAvailablePerTank: false,
          chart: this.state.chart,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoadingAvailablePerTank: false});
      });
  }

  getTopFiveFillLevel(isLoadingFillLevel) {
    const {user} = this.state;

    let request_body = this.returnFilters();

    request_body.page = 1;
    request_body.limit = 5;
    request_body.tag = 'rank';
    request_body.order = 'level_percentage';

    this.setState({
      isLoadingFillLevel,
    });

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_level_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data} = res.data;

        let chart_data = [],
          labels = [],
          {top_five_fill_level} = this.state;

        for (let i = 0; i < data.length; i++) {
          if (data[i].thing && data[i].thing.length > 0) {
            labels.push(
              `${data[i].thing[0].address.geolocation} (${data[
                i
              ].thing[0].type.toLowerCase()})`,
            );

            top_five_fill_level.push({
              label: data[i].thing[0].thing_id,
              value: Math.round(data[i].average_percentage),
            });

            let label = `${data[i].thing[0].address.geolocation} (${data[
              i
            ].thing[0].type.toLowerCase()})`;

            chart_data.push([label, Math.round(data[i].average_percentage)]);
          }
        }

        chart_data = chart_data.sort((a, b) => b[1] - a[1]);
        chart_data = chart_data.map(el => el[1]);

        let customLabels = labels.map(
          (label, index) => `${label}: ${chart_data[index]}%`,
        );

        this.state.chart.fill_level = {
          labels: customLabels,
          datasets: [
            {
              data: chart_data,
              backgroundColor: colors(1),
            },
          ],
        };

        this.setState({
          isLoadingFillLevel: false,
          chart: this.state.chart,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoadingFillLevel: false});
      });
  }

  getTopFiveWaterUsed(isLoadingChartWaterUsed) {
    const {user} = this.state;

    let request_body = this.returnFilters();

    request_body.page = 1;
    request_body.limit = 5;
    request_body.tag = 'all';
    request_body.order = 'object';

    this.setState({
      isLoadingChartWaterUsed,
    });

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_used_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const {data} = res.data;

        let chart_data = [],
          labels = [],
          {top_five_water_used} = this.state;

        for (let i = 0; i < data.length; i++) {
          if (data[i].thing && data[i].thing.length > 0) {
            labels.push(
              `${data[i].thing[0].address.geolocation} (${data[
                i
              ].thing[0].type.toLowerCase()})`,
            );

            top_five_water_used.push({
              label: data[i].thing[0].thing_id,
              value: numberWithCommas(Math.round(data[i].total_level)),
            });

            let label = `${data[i].thing[0].address.geolocation} (${data[
              i
            ].thing[0].type.toLowerCase()})`;

            chart_data.push([label, Math.round(data[i].total_used)]);
          }
        }
        chart_data = chart_data.sort((a, b) => b[1] - a[1]);
        chart_data = chart_data.map(el => el[1]);

        let customLabels = labels.map(
          (label, index) => `${label}: ${numberWithCommas(chart_data[index])}`,
        );

        this.state.chart.water_used = {
          labels: customLabels,
          datasets: [
            {
              data: chart_data,
              backgroundColor: colors(1),
            },
          ],
        };

        this.setState({
          isLoadingChartWaterUsed: false,
          chart: this.state.chart,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoadingChartWaterUsed: false});
      });
  }

  getWeeklyTrends(isLoadingWeekly) {
    const {user} = this.state;

    let request_body = this.returnFilters();

    // request_body.status = 'served';
    request_body.tag = 'trend';
    request_body.target = 'daily';
    request_body.order = 'day_of_week';

    this.setState({
      isLoadingWeekly,
    });

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_level_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const data = res.data;

        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          labels.push(days[data[i]._id]);

          chart_data.push(Math.round(data[i].total_level));
        }

        this.state.chart.weekly_trends = {
          labels: labels,
          datasets: [
            {
              label: 'Total level per day',
              data: chart_data,
              backgroundColor: colors(0.1),
              borderColor: colors(1),
              radius: 5,
            },
          ],
        };

        this.setState({
          isLoadingWeekly: false,
          chart: this.state.chart,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoadingWeekly: false});
      });
  }

  handlePressCount(route) {
    this.setState({route});
  }

  returnChartList(data) {
    return (
      <ul className="chart-list">
        {data &&
          data.map((item, i) => {
            let title = item.split(':');
            return (
              <li key={i}>
                <div style={{flex: 1, marginRight: 20}}>
                  <span className="title">{title[0]}</span>
                </div>

                <span className="number">{title[1]}</span>
              </li>
            );
          })}
      </ul>
    );
  }

  getHourlyWaterLevel(isLoadingHourlyWaterLevel) {
    const {user} = this.state;

    this.setState({
      isLoadingHourlyWaterLevel,
    });

    let request_body = this.returnFilters();

    request_body.tag = 'trend';

    request_body.order = 'hourly';

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_level_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const data = res.data;
        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          labels.push(data[i]._id + 'h');
          chart_data.push(Math.round(data[i].total_level * 100) / 100);
        }

        this.state.chart.hourly_Water_level = {
          labels,
          datasets: [
            {
              label: 'Levels per hour (L)',
              data: chart_data,
              backgroundColor: colors(0.1),
              borderColor: colors(1),
              radius: 5,
            },
          ],
        };

        this.setState({
          isLoadingHourlyWaterLevel: false,
          chart: this.state.chart,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoadingHourlyWaterLevel: false});
      });
  }

  getDailyWaterLevel(isLoadingDailyWaterLevel) {
    const {user} = this.state;

    this.setState({
      isLoadingDailyWaterLevel,
    });

    let request_body = this.returnFilters();

    request_body.tag = 'trend';

    request_body.order = 'daily';

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_level_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const data = res.data;

        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          labels.push(data[i]._id);
          chart_data.push(Math.round(data[i].total_level * 100) / 100);
        }

        this.state.chart.daily_Water_level = {
          labels,
          datasets: [
            {
              label: 'Levels per day (L)',
              data: chart_data,
              backgroundColor: colors(0.1),
              borderColor: colors(1),
              radius: 5,
            },
          ],
        };

        this.setState({
          isLoadingDailyWaterLevel: false,
          chart: this.state.chart,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoadingDailyWaterLevel: false});
      });
  }

  getDailyLevelThingType(isLoadingThingType) {
    const {user} = this.state;

    this.setState({
      isLoadingThingType,
    });

    let request_body = this.returnFilters();

    request_body.tag = 'trend';

    request_body.order = 'thing_type';

    const options = {
      method: 'POST',
      url: ENDPOINT + '/get_water_level_info',
      data: request_body,
      headers: {
        authorization: 'Bearer ' + user.token,
      },
    };

    axios(options)
      .then(res => {
        const data = res.data;
        let chart_data = [],
          labels = [];

        for (let i = 0; i < data.length; i++) {
          // if (data[i]._id === 'Tank' || data[i]._id === 'Traffic') {
          //   labels.push(data[i]._id);
          //   chart_data.push(Math.round(data[i].total_level * 100) / 100);
          // }
          labels.push(data[i]._id);
          chart_data.push(Math.round(data[i].total_level * 100) / 100);
        }

        let customLabels = labels.map(
          (label, index) =>
            `${label}: (${numberWithCommas(chart_data[index])}L)`,
        );

        this.state.chart.thing_type = {
          labels: customLabels,
          datasets: [
            {
              data: chart_data,
              backgroundColor: colors(1),
            },
          ],
        };

        this.setState({
          chart: this.state.chart,
          isLoadingThingType: false,
        });
      })
      .catch(error => {
        toastMessage('error', error);
        this.setState({isLoadingThingType: false});
      });
  }

  render() {
    if (this.state.route !== '') {
      return <Redirect to={this.state.route} />;
    }

    return (
      <div>
        <div className="row">
          <div className="col-6 col-md-3">
            <CardCount
              title="Estimated Total Water Used"
              total={
                this.state.isLoadingWaterUsed
                  ? '...'
                  : `${numberWithCommas(
                      Math.round(this.state.total_Water_used),
                    )}L`
              }
              icon="bxs-calendar-event"
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Total Number of Tanks"
              total={
                this.state.isLoadingWater
                  ? '...'
                  : numberWithCommas(this.state.total_number_tanks)
              }
              icon="bxs-data"
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Estimated Total Water Available"
              total={
                this.state.isLoadingWater
                  ? '...'
                  : numberWithCommas(this.state.total_Water) + ' L'
              }
              icon="bxs-droplet-half"
            />
          </div>
          <div className="col-6 col-md-3">
            <CardCount
              title="Total Average Water Available per Tank"
              total={
                this.state.isLoadingWater
                  ? '...'
                  : `${numberWithCommas(
                      Math.round(
                        this.state.total_Water / this.state.total_number_tanks,
                      ),
                    )}L`
              }
              icon="bx-grid-alt"
            />
          </div>
        </div>
        <div className="row" style={{marginTop: '1rem'}}>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Top 5 Water Available per Tank</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingAvailablePerTank ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{height: 180}}>
                      <Doughnut
                        data={this.state.chart.water_available_per_tank}
                        options={options}
                      />
                    </div>
                    {returnChartList(
                      this.state.chart.water_available_per_tank.labels
                        ? this.state.chart.water_available_per_tank.labels
                        : [],
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <WaterUsedPerTank />
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Top 5 Fill Level Per Tank</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingFillLevel ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div style={{height: 180}}>
                      <Doughnut
                        data={this.state.chart.fill_level}
                        options={options}
                      />
                    </div>
                    {returnChartList(
                      this.state.chart.fill_level.labels
                        ? this.state.chart.fill_level.labels
                        : [],
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{marginTop: '1rem'}}>
          <div className="col-md-12">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Hourly Max Levels</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingHourlyWaterLevel ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.hourly_Water_level}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{marginTop: '1rem'}}>
          <div className="col-md-12">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Daily Max Levels</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingDailyWaterLevel ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.daily_Water_level}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{marginTop: '1rem'}}>
          <div className="col-md-8">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>WeekDay Max Levels</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingTopCollection ? (
                  <LoadingSpinner />
                ) : (
                  <Line
                    data={this.state.chart.weekly_trends}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      bezierCurve: true,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-analytics">
              <div className="card-header">
                <h3>Max Level Per Type</h3>
              </div>
              <div className="card-body">
                {this.state.isLoadingThingType ? (
                  <LoadingSpinner />
                ) : (
                  <Doughnut
                    data={this.state.chart.thing_type}
                    options={{
                      legend: {
                        position: 'bottom',
                        display: true,
                      },
                      maintainAspectRatio: false,
                      responsive: true,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {filters} = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Analytics);
