import React from 'react';
import {Home} from './common';
import {AirQuality} from '../components/AirQuality';

class AirQualityScreen extends React.Component {
  render() {
    return (
      <div>
        <Home date location type />
        <div>
          <AirQuality />
        </div>
      </div>
    );
  }
}

export default AirQualityScreen;
